import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, Subject, Subscription, throwError } from 'rxjs';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BlockCopyPasteDirective } from '../../../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { GrecaptchaService } from 'src/app/shared/services/grecaptcha.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { VerifyType } from '../../verify-email/verify-email.component';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { RegistrationService } from 'src/app/api/services/registration/registration.service';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';

function equalTo(key: string, confirmationKey: string) {
  return (group: UntypedFormGroup) => {
    const input = group.controls[key];
    const confirmationInput = group.controls[confirmationKey];
    return confirmationInput.setErrors(input.value !== confirmationInput.value ? { notEquivalent: true } : null);
  };
}

const ECE_NUMBER_REGEX = /^1\d{8}$/;

@Component({
  selector: 'app-org-register',
  templateUrl: './org-register.component.html',
  styleUrls: ['./org-register.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    NgClass,
    BlockCopyPasteDirective,
    RouterLink,
    MatRadioModule,
    SnackbarComponent,
  ],
})
export class OrgRegisterComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  hide = true;
  ohide = true;
  chide = true;
  ochide = true;
  orgSelected: boolean = false;
  public organization: UntypedFormGroup = Object.create(null);
  industries: any[] = [];
  states: any[] = [];
  validEmailSpinner: boolean = false;
  disableFlag: boolean = false;
  cancelledInvite: boolean = false;
  orgName: string = '';
  verifyEmail: boolean = false;
  showExtNumQuestion: boolean = false;
  showExtNumField: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public lookupsService: LookupsService,
    private registrationService: RegistrationService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private reCaptchaService: GrecaptchaService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.lookupsService.getIndustries();
    this.lookupsService.getStates();

    this.queryParamsData();
    let URLreg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.organization = this.fb.group(
      {
        firstName: this.fb.control(null, Validators.compose([Validators.required])),
        lastName: this.fb.control(null, Validators.compose([Validators.required])),
        email: this.fb.control(null, Validators.compose([Validators.required, Validators.email as ValidatorFn])),
        name: this.fb.control(null, Validators.compose([Validators.required])),
        password: this.fb.control(null, Validators.compose([Validators.required])),
        confirmPassword: this.fb.control(null, Validators.compose([Validators.required])),
        confirmEmail: this.fb.control(null, Validators.compose([Validators.required])),
        stateId: this.fb.control(null, Validators.compose([Validators.required])),
        industryId: this.fb.control(null),
        website: this.fb.control(null, Validators.pattern(URLreg)),
        isIndividual: this.fb.control(false),
      },
      { validator: [equalTo('email', 'confirmEmail'), equalTo('password', 'confirmPassword')] },
    );
  }

  ngAfterViewInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params) {
        this.organization.get('firstName')?.patchValue(params.firstname);
        this.organization.get('lastName')?.patchValue(params.lastname);
        if(params.email) {
          this.organization.get('email')?.patchValue(decodeURIComponent(params.email));
          this.checkEmail(decodeURIComponent(params.email), this.organization.controls['email']);
        }
      }
    });
  }

  // TODO: Make sure this method works. It shouldnt work with signals and should be turned into computed signal or effect
  queryParamsData() {
    return combineLatest([this.lookupsStore.statesSignal(), this.lookupsStore.industriesSignal(), this.route.queryParams])
      .pipe(
        tap(([states, industries, params]) => {
          if (states.length > 0 && industries.length > 0 && params) {
            const { state, industry } = params;
            if (state || industry) {
              if (state) {
                const orgstateCtrl = this.organization.get('stateId');
                const val = this.getValueFromParams(states, state);
                orgstateCtrl?.patchValue(val);
              }
              if (industry) {
                const orgindustryCtrl = this.organization.get('industryId');

                const val = this.getValueFromParams(industries, industry);
                orgindustryCtrl?.patchValue(val);
              }
            }
          }

          if (params) {
            if (params.cancelledInvite) {
              this.cancelledInvite = true;
              this.orgName = params.orgName;
            }
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  registerOrganization() {
    if (!this.organization.valid) {
      this.organization.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          if (error?.field === 'password') {
            const control = this.organization.get('password');
            if (control) {
              this.organization.markAllAsTouched();
              control.setErrors({
                message: `The new password does not meet the requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and a number`,
              });
            }
          } else {
            const control = this.organization.get(error.field);
            if (control) {
              this.organization.markAllAsTouched();
              control.setErrors({
                message: error.message,
              });
            }
          }
        });
      } else {
        this.disableFlag = false;
        const errors = Object.keys(validationErrors.error);
        if (errors.length) {
          if (errors[0] === 'reCaptcha') {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: {
                toastType: ToastType.Error,
                message:
                  'Error occurred during reCAPTCHA validation. If this problem persists, click the Help button for additional assistance.',
              },
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Error occured' },
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Error occured' },
          });
        }
      }

      return of(null);
    };

    const formValues = {
      ...this.organization.getRawValue(),
    };

    this.reCaptchaService.execute('registerOrg').then((token: any) => {
      formValues.reCaptchaToken = token;
      this.registrationService
        .addOrganization(formValues)
        .pipe(
          catchError((error) => throwError(error)),
          takeUntil(this.destroy$),
        )
        .subscribe(
          () => {
            const email = this.organization.get('email')?.value;
            const forgot = false;
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Success, message: 'Successfully Registered' },
            });
            this.router.navigateByUrl('/verify-email', {
              state: { email, forgot, verifyType: VerifyType.VerifyEmail },
            });
            this.disableFlag = false;
          },
          (error) => {
            console.log(error);
            validationErrorHandler(error);
            this.disableFlag = false;
          },
        );
    });
  }

  checkEmail(email: any, control: any) {
    this.verifyEmail = false;

    if (control?.errors === null) {
      this.validEmailSpinner = false;
      this.authService
        .emailExist(email)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (data.userExists && !data.inviteExists) {
              control.markAsTouched();
              control.setErrors({
                message: `Username '${email}' is already taken.`,
              });
            } else if (data.inviteExists) {
              control.markAsTouched();
              control.setErrors({
                invite: { message: 'You have an open invitation.', email: `${email}` },
              });
            }
            // Now underline and allow "Resend Verification Email" button to appear
            // Like the "Forgot Password" button, this button will send an email to the user
            else if (!data.emailConfirmed && data.userExists) {
              control.markAsTouched();
              this.verifyEmail = true;
            }

            this.validEmailSpinner = false;
          },
          (error) => {
            this.validEmailSpinner = false;
          },
        );
    }
  }

  getValueFromParams(arr: any, item: any) {
    const extractVal = item
      .split('-')
      .map((word: any) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');

    const filterArr = arr.filter((its: any) => {
      if (its.label.toLowerCase().trim() === extractVal.toLowerCase()) {
        return true;
      }
    });

    return filterArr[0]?.key;
  }
}
