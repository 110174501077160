import { Component, Inject, OnInit, OnDestroy, inject } from '@angular/core';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';

@Component({
  selector: 'app-select-learner-emails',
  templateUrl: './select-learner-emails.component.html',
  styleUrls: ['./select-learner-emails.component.scss'],
  standalone: true,
  imports: [
    ModalLayoutComponent,
    NgIf,
    NgFor,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ]
})
export class SelectLearnerEmailsComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  searchLearnerForm = this.fb.group({
    search: ['']
  });
  learners!: any[];
  selectedLearners: any[] = [];
  constructor(
    private lookupsService: LookupsService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SelectLearnerEmailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.lookupsService.getLearnerEmails('')

    this.searchLearnerForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.lookupsService.getLearnerEmails(value.search)
    });
  }

  addLearner(learner: any) {
    this.selectedLearners.push(learner);
  }

  removeLearner(learner: any) {
    this.selectedLearners = this.selectedLearners.filter((l) => l.value !== learner.value);
  }

  isSelected(learner: any) {
    return this.selectedLearners.find((l) => l.value === learner.value);
  }

  selectLearners() {
    this.dialogRef.close(this.selectedLearners);
  }
}
