<div class="text-center w-full">
  <div class="w-full flex justify-center items-center m-0">
    <div class="sm:w-4/5">
      <form [formGroup]="addUsers">
        <p class="w-full">
          Enter the email of the learner and assign them to a group(s)
        </p>
        <ng-container formArrayName="learners" *ngIf="true">
          <div *ngFor="let control of learnersAccessControl.controls; let i = index">
            <ng-container [formGroupName]="i">
              <div class="m-b-0 flex lg:gap-4 flex-row">
                <div class="w-1/2 pr-2">
                  <mat-form-field class="w-full">
                    <mat-label>Email</mat-label>
                    <input type="text" (blur)="checkEmail($event, control.controls['email'])" matInput
                      formControlName="email" />
                    <mat-error
                      *ngIf="control.controls['email'].hasError('required') && control.controls['email'].touched">
                      Required
                    </mat-error>
                    <mat-error *ngIf="control.controls['email'].errors?.email && control.controls['email'].touched">You
                      must include a valid email.
                    </mat-error>
                    <mat-error *ngIf="control.controls['email'].errors?.message">{{
                      control.controls['email'].getError('message')
                      }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="w-1/2 flex">
                  <div [ngClass]="i > 0 ? 'w-11/12' : 'w-full'">
                    <app-form-select label="Group" multiple="true" formControlName="groupIds"
                      [options]="this.lookupsStore.groupsSignal()" class="w-full"></app-form-select>
                  </div>
                  <div class="w-1/12 flex justify-center items-center" *ngIf="i > 0">
                    <mat-icon (click)="deleteItem(i)" class="btn-action">close</mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <p *ngIf="learnerDisabled" class="text-secondary">Max 10 learners may be added at a time!</p>
        <div class="w-full flex justify-center items-center">
          <button type="button" (click)="addElement({ type: 'learners' })" [disabled]="learnerDisabled"
            [ngClass]="learnerDisabled ? 'disabled': 'btn primary-link'"
            class="flex flex-row items-center text-center justify-center">
            <span>Add Another Learner</span>
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
        <div *ngIf="errorResponses.length > 0" class="block">
          <p class="text-red-500">The following errors occured:</p>
          <div *ngFor="let response of errorResponses" class="overflow-y-auto max-h-24 mt-1 border-solid border-1 p-1">
            <p class="text-red-500">{{response}}</p>
          </div>
        </div>
        <div>
          <p class="my-8">An invite will be emailed to the learners.</p>
        </div>
      </form>
    </div>
  </div>
  <div class="w-full block justify-center items-center">
    <!-- <div class="button-cancel">
      <button class="bttn bttn-secondary mr-1" (click)="closeModel()">Cancel</button>
    </div> -->
    <div>
      <button class="btn-primary medium" type="button" [disabled]="disableFlag" (click)="onSubmitUsers()">
        <ng-container>Add Learners</ng-container>
      </button>
    </div>
    <div class="pt-3 inline-block">
      <button class="primary-link flex justify-center items-center" type="button" (click)="backStep()">
        <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
        <span>Back</span>
      </button>
    </div>
  </div>
</div>