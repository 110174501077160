import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: true,
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.authService.logout();
  }
}
