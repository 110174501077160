import { inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';
import { AuthenticationStore } from 'src/app/api/services/authentication/authentication.store';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  readonly authenticationStore = inject(AuthenticationStore);
  userLoggedIn$ = toObservable(this.authenticationStore.userIsLoggedInSignal);
  redirectTo: boolean = false;
  inviteUrl!: string;
  constructor(
    private authServ: AuthenticationService,
     private router: Router
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const location = window.location.pathname;
    if (location === '/accept-invitation-confirmed' || location === '/email-confirmed') {
      this.authServ.flushAllStorage();
      this.inviteUrl = this.router.url;
      this.redirectTo = true;
    }
    return this.userLoggedIn().pipe(take(1));
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn().pipe(take(1));
  }

  private userLoggedIn() {
    return this.userLoggedIn$.pipe(
      map((userIsLoggedIn) => {
        if (userIsLoggedIn) {
          return true;
        } else {
          if (!this.redirectTo && window.location.pathname !== '/login') {
            const currentUrl = window.location.pathname + window.location.search;
            this.router.navigateByUrl(`/login?redirecturl=${currentUrl}`);
          }
          return false;
        }
      })
    );
  }
}
