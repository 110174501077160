import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    MatDialogModule,
    MatSnackBarModule,
  ],
  providers:[
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class MaterialModule {}

