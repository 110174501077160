import { AfterContentInit, Component, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, NgControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BaseControlValueAccessor } from '../../base-control-value-accessor';
import { Lookup } from '../../../shared/interfaces/lookup.interfaces';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-form-select',
    templateUrl: './form-select.component.html',
    styleUrls: ['./form-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormSelectComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
    ],
})
export class FormSelectComponent extends BaseControlValueAccessor<string> implements OnInit, AfterContentInit {
  @Input() label = '';
  @Input() options!: Lookup;
  @Input() multiple?: any;
  @Output() selectionChange = new EventEmitter();
  control!: UntypedFormControl;

  constructor(private injector: Injector) {
    super();
  }

  ngAfterContentInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null) as NgControl;
    if (ngControl) {
      this.control = ngControl.control as UntypedFormControl;
    } else {
      // Component is missing form control binding
    }
  }

  ngOnInit(): void {}
  compareFn(x: any, y: any) {
    if (x && y) {
      return x && y ? x.toString() === y.toString() : x.value.toString() === y.value.toString();
    }
  }
}
