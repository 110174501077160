import { Component, Inject, OnInit, OnDestroy, inject } from '@angular/core';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, of, throwError } from 'rxjs';
import { catchError, take, tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { NgIf, NgFor, AsyncPipe, NgClass } from '@angular/common';
import { OrganizationService } from 'src/app/api/services/organizations/organization.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationStore } from 'src/app/api/services/organizations/organization.store';
@Component({
  selector: 'app-select-assign-training',
  templateUrl: './select-assign-training.component.html',
  styleUrls: ['./select-assign-training.component.scss'],
  standalone: true,
  imports: [
    ModalLayoutComponent,
    NgIf,
    AsyncPipe,
    NgFor,
    NgClass,
    SnackbarComponent,
    MatIconModule
  ],
})
export class SelectAssignTrainingComponent implements OnInit, OnDestroy {
  readonly organizationStore = inject(OrganizationStore);
  constructor(
    private organizationService: OrganizationService,
    private dialogRef: MatDialogRef<SelectAssignTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.organizationStore.resetEditCourse();
    this.reloadOrgCourses();
  }

  ngOnDestroy(): void {
    this.organizationStore.resetEditCourse();
  }

  selectCourse(course: any) {
    if (!course?.alreadyUserCourses) {
      this.organizationStore.updateEditCourse(course.id);
    }
  }

  reloadOrgCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    const entityType = window.localStorage.getItem('entityType');
    if (!entityGuid) {
      return;
    }

    if (entityType !== 'Organization' && entityType !== 'Coop') {
      return;
    }

    if (this.organizationStore.orgSignal()?.length === 0) {
      this.organizationService.getOrgCourses(entityGuid);
    }
  }

  selectTraining() {
    this.dialogRef.close(this.organizationStore.selectedCoursesSignal());
  }
}