import { Injectable } from '@angular/core';
import { paths } from '../../interfaces/swagger-types';
import { HttpClient } from '@angular/common/http';

export type IndividualRegister =
  paths['/api/Registration/totp-register-individual']['post']['requestBody']['content']['text/json'];
export type OrganizationRegistration =
  paths['/api/Registration/totp-register-org']['post']['requestBody']['content']['text/json'];

@Injectable({
  providedIn: 'root',
})
export class RegistrationService{
  constructor(
    private http: HttpClient,
  ) {}

  public addIndividual(form: IndividualRegister) {
    return this.http.post('/registration/totp-register-individual', form);
  }

  public addOrganization(form: OrganizationRegistration) {
    return this.http.post('/registration/totp-register-org', form);
  }
}