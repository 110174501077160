<ng-container *ngIf="!hasSubscription; else subscribed">
  <app-boxy-message   
    [boxyMessageOptions]="boxyMessage"
    (buttonClicked)="onBoxyButtonClick()"
  ></app-boxy-message>
</ng-container>

<ng-template #subscribed>
  <div class="content-container ml-4 mr-4">
    <h1 class="learner">Share Organization Training Web Page</h1>
    <p class="medium pt-2">Use your organization's training web page to allow your team to join without an invitation. 
      By enabling this setting, learners with access to the link can join your organization without needing your approval.
      <a
        class="text-right primary-link"
        href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/18755043082267"
        target="_blank"
      >Learn More</a
    ></p>
    <form [formGroup]="form" (ngSubmit)="updateSettings()">
    <div class="mt-auto flex flex-row">
        <div class="flex flex-col my-8">
            <mat-slide-toggle formControlName="publicUserAssociationToggle" (change)="setPublicPageStatus()"></mat-slide-toggle>
        </div>
        <div class="flex flex-col my-6 pl-5">
          <h4 class="learner">Enable Organization Web Page</h4>
          <p [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">{{ form.get('publicUserAssociationURL')?.value }}</p>
        </div>
    </div>
    <button class="btn-secondary medium" type="button" [disabled]="!orgData.publicLandingPageEnabled" (click)="clipBoard()">
      Copy Link To Clipboard
    </button>   
    <hr class="mt-9">
    <h2 class="learner pt-8" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Web Page Link Settings</h2>
    <div class="mt-4 w-full flex-col lg:flex-row flex gap-8">
      <div class="w-full lg:w-1/2">
        <h3 class="learner pt-1" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Customized URL</h3>
        <p class="small" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">
          Create a custom URL for your webpage link. 
        </p>  
        <p class="small" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">
          For example: <span><b><i>invite.mandatedreportertraining.com/yourorganization</i></b></span>
        </p>  
        <div class="custom-url-input w-full mt-10">
          <span [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">invite.mandatedreportertraining.com/</span>
          <input 
            id="slug" 
            type="text" 
            placeholder="Customized URL"
            formControlName="slug" 
            (blur)="isSlugValid()"
            [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}"
          />  
        </div>
        <mat-error class="mt-2" *ngIf="!slugIsValid && form.controls['slug'].touched">
          URL slug already exists, please make it unique
        </mat-error>  
      </div>    
      <div class="flex lg:w-1/2 mt-8 lg:mt-0">
        <div class="w-full pb-2">
            <h3 class="learner pt-1" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">Email Allow List</h3>
            <p class="small pb-8" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">
              Entering an email domain here will allow only individuals with that domain in their email address to join your organization. Leaving this blank allows any individual to join.
            </p>              
            <ng-container formArrayName="whiteListedDomains" *ngIf="true">
              <div *ngFor="let control of whiteListedDomains.controls; let i = index">
                  <div class="m-b-0 flex flex-row">
                    <div class="w-full pr-2">
                      <mat-form-field class="w-full">
                        <mat-label>Email Domain</mat-label>
                        <input 
                          type="text" 
                          matInput
                          [formControlName]="i"
                        />
                      </mat-form-field>
                    </div>
                    <mat-icon (click)="removeDomain(i)" class="btn-action pt-7" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">close</mat-icon>
                  </div>
              </div>
            </ng-container>
            <div class="w-full flex">
              <button 
                type="button" 
                (click)="addDomain()" 
                [disabled]="!orgData.publicLandingPageEnabled"
                class="flex primary-link cursor-pointer items-center"
              >
                <mat-icon class="custom-mat-icon mr-2" [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}">add</mat-icon>
                <span [ngClass]="{'tertiary-text-color': !orgData.publicLandingPageEnabled}"><h3 class="learner">Add Email Domain</h3></span>
              </button>
            </div>
        </div>
      </div>
    </div>
    <div class="mt-10 text-center">
      <button type="submit" class="btn-primary large" [disabled]="!orgData.publicLandingPageEnabled">
        Update Settings
      </button>
    </div>
    </form>
  </div>
</ng-template>