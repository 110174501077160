import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { MatIconModule } from '@angular/material/icon';
import { BlockCopyPasteDirective } from '../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';

function equalTo(key: string, confirmationKey: string) {
  return (group: UntypedFormGroup) => {
    const input = group.controls[key];
    const confirmationInput = group.controls[confirmationKey];
    return confirmationInput.setErrors(input.value !== confirmationInput.value ? { notEquivalent: true } : null);
  };
}

@UntilDestroy()
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatSnackBarModule,
        BlockCopyPasteDirective,
        MatIconModule,
        NgClass,
        SnackbarComponent
    ],
})
export class InviteRegisterComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  public registerForm: UntypedFormGroup = Object.create(null);
  hide = true;
  ohide = true;
  chide = true;
  ochide = true;
  disableFlag: boolean = false;
  inviteGuid!: string;
  inviteValid: boolean = false;
  inviteOrgName!: string;
  isJoinRequest: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private router: Router,
    private inviteConfirmService: InviteAcceptConfirmedService,
    public lookupsService: LookupsService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getLanguages();

    this.inviteConfirmService.inviteInfo$.pipe(untilDestroyed(this)).subscribe((inviteInfo) => {
      if (inviteInfo) {
        if (inviteInfo?.inviteValid) {
          this.inviteValid = true;
          this.inviteOrgName = inviteInfo?.inviteOrgName;

          if (inviteInfo?.isJoinRequest) {
            this.isJoinRequest = true;
          }
        }

        let email = '';
        const emailDecode = decodeURIComponent(inviteInfo?.email!);
        const stringContainsSpace = /\s/g.test(emailDecode);
        if (stringContainsSpace) {
          email = emailDecode.replace(' ', '+');
        } else {
          email = emailDecode;
        }

        this.inviteGuid = inviteInfo?.inviteGuid!;

        this.registerForm = this.fb.group(
          {
            firstName: this.fb.control(null, Validators.required),
            lastName: this.fb.control(null, Validators.required),
            email: this.fb.control(
              { value: email, disabled: true },
              Validators.compose([Validators.required, Validators.email as unknown as ValidatorFn])
            ),
            confirmEmail: this.fb.control({ value: email, disabled: true }, Validators.compose([Validators.required])),
            password: this.fb.control(null, Validators.required),
            confirmPassword: this.fb.control(null, Validators.compose([Validators.required])),
            languageId: this.fb.control(null),
            zipcode: this.fb.control(null),
          },
          { validator: [equalTo('email', 'confirmEmail'), equalTo('password', 'confirmPassword')] }
        );
      }
    });
  }

  registerUser() {
    if (!this.registerForm.valid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          if (error?.field === 'password') {
            const control = this.registerForm.get('password');
            if (control) {
              this.registerForm.markAllAsTouched();
              control.setErrors({
                message: `The new password does not meet the requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and a number`,
              });
            }
          } else {
            const control = this.registerForm.get(error.field);
            if (control) {
              this.registerForm.markAllAsTouched();
              control.setErrors({
                message: error.message,
              });
            }
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };
    const { firstName, lastName, password, email, languageId, zipcode } = this.registerForm.getRawValue();

    const formValues = {
      inviteGuid: this.inviteGuid,
      firstName,
      lastName,
      password,
      languageId,
      zipcode
    };
    const loginValues = {
      userName: email,
      password: password,
    };

    this.inviteConfirmService
      .registerInviteUser(formValues)
      .pipe(
        catchError((e) => throwError(() => e)),
        switchMap(() => {
          return this.authService.login(loginValues).pipe(
            untilDestroyed(this),
            tap((success) => {
              if (!success) {
                this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Incorrect username/password" }})
                this.disableFlag = false;
              }
            }),
            filter((success) => Boolean(success))
          );
        })
      )
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Login Successful" }})
          this.disableFlag = false;
          this.router.navigateByUrl('/');
        },
        (e) => {
          validationErrorHandler(e);
          this.disableFlag = false;
        }
      );
  }

  onTabClick(event: MatTabChangeEvent) {
    this.hide = true;
    this.ohide = true;
    this.chide = true;
    this.ochide = true;
  }

  goToLogin() {
    this.inviteConfirmService.inviteInfo$.next(null);
    this.router.navigateByUrl('/login');
  }
}
