import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { SubscriptionStore } from 'src/app/api/services/subscription/subscription.store';

export const subscriptionGuard: CanActivateFn = (route, state) => {
  const subscriptionstore = inject(SubscriptionStore);
  const router = inject(Router);

  const subscription = subscriptionstore.subscriptionSignal();

  if (subscription?.hasActiveSubscription || !subscription?.isTrialOver || (subscription?.certificateQuota != undefined && subscription?.certificateQuota > 0)) {
    if(state.url === '/subscribe') {
      return router.createUrlTree([route.data.redirectTo || '/organization/account-billing']);
    }
    return true;
  } else {
    if(state.url === '/subscribe') {
      return true;
    }
    return router.createUrlTree([route.data.redirectTo || '/subscribe']);
  }
};
