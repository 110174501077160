import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationErrors } from '../../../../api/interfaces/validation-errors';
import { Subject, of, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserGroupsService } from 'src/app/api/services/userGroups/user-groups.service';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { OrganizationStore } from 'src/app/api/services/organizations/organization.store';

export type Mode = 'add' | 'edit' | 'delete';
export interface DialogData {
  mode?: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId: any;
  itemName?: string;
}

@Component({
    selector: 'app-add-user-groups',
    templateUrl: './add-user-groups.component.html',
    styleUrls: ['./add-user-groups.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgClass,
        MatIconModule,
        SnackbarComponent
    ],
})
export class AddUserGroupsComponent implements OnInit, OnDestroy {
  readonly organizationStore = inject(OrganizationStore);
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  disableFlag: boolean = false;
  public addGroups: UntypedFormGroup = Object.create(null);
  public selectedCourses: any[] = [];
  addGroupCoursesBtn: boolean = true;
  mode: Mode;
  groupListNumber: any;
  groupId!: number;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddUserGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private userGroupState: UserGroupsService,
    public lookupsService: LookupsService
  ) {
    this.mode = data.mode || 'add';
  }

  ngOnInit(): void {
    this.lookupsService.getEntityGroupStatus();
    
    this.addGroups = this.fb.group({
      name: this.fb.control(null, Validators.required),
      isActive: this.fb.control(null, Validators.required),
    });
    if (this.mode === 'edit') {
      this.data?.item?.courseIds.forEach((id: number) => {
        this.organizationStore.updateGroupCourse(id);
      });
      this.groupListNumber = this.data?.item?.numberOfUsers;
      this.groupId = this.data?.item?.id;
      this.addGroups.patchValue({
        ...this.data.item,
        isActive: this.data.item.statusId,
      });
    }
  }

  onSubmitGroups() {
    if (!this.addGroups.valid) {
      this.addGroups.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors) => {
      if (validationErrors.length) {
        validationErrors.forEach((error) => {
          const control = this.addGroups.get(error.field);
          if (control) {
            this.addGroups.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };

    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    let courseIds: any[] = [];
   
    const activeButtonArray = this.organizationStore.orgSignal()?.filter((item: any) => {
      return item.course.groupCourse === true;
    });
    if (activeButtonArray && activeButtonArray.length > 0) {
      activeButtonArray.forEach((item: any) => {
        courseIds.push(item?.courseId);
      });
    }

    if (this.data?.mode === 'add') {
      const { isActive, ...remaining } = this.addGroups.value;
      const addGroupsObject = {
        courseIds: courseIds,
        entityGuid: entityGuid,
        statusId: isActive,
        ...remaining,
      };
      this.userGroupState
        .upsertGroup(addGroupsObject)
        .pipe(
          catchError((err) => {
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (data) => {
            this.closeModal();
            this.lookupsService.getGroups();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Group Added' }})
            this.disableFlag = false;
          },
          error: (err) => {
            validationErrorHandler(err);
            this.disableFlag = false;
          },
        });
    } else if (this.data?.mode === 'edit') {
      const { isActive, name, ...remaining } = this.addGroups.value;

      const addGroupsObject = {
        courseIds: courseIds,
        statusId: isActive,
        groupName: name,
        ...remaining,
      };

      this.userGroupState
        .updateGroup(this.data?.item?.id, addGroupsObject)
        .pipe(
          catchError((err) => {
            return throwError(err);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (data) => {
            this.closeModal();
            this.lookupsService.getGroups();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Group Updated' }})
            this.disableFlag = false;
          },
          error: (err) => {
            validationErrorHandler(err);
            this.disableFlag = false;
          },
        });
    }

  }

  goToGrid() {
    this.router.navigateByUrl(`/team/all-users?groupId=${this.groupId}`).then();
    this.closeModal();
  }
  goToOrg() {
    this.router.navigateByUrl('/trainings/trainings').then();
    this.closeModal();
  }
  closeModal() {
    this.dialogRef.close();
  }

  handleCourseSelection(course: any, selected: any) {
    const selectCourses = [...this.selectedCourses];

    const i = selectCourses.findIndex((sCourse) => sCourse.id === course.id);
    if (i >= 0) {
      selectCourses.splice(i, 1);
    }
    if (selected?.courseSelected) {
      selectCourses.push(course);
    }

    this.selectedCourses = selectCourses;
    this.cdr.detectChanges();
  }
  
  selectCourse(course: any) {
    this.organizationStore.updateGroupCourse(course.id);
  }

  ngOnDestroy(): void {
    this.organizationStore.resetGroupCourse();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
