import { Component, OnInit, } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { OffsiteCertsService } from 'src/app/api/services/offsite-certs/offsite-certs.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';

@Component({
  selector: 'app-offsite-certs',
  templateUrl: './offsite-certs.component.html',
  standalone: true,
  imports: [
    SnackbarComponent,
    MRTGridTemplateComponent
  ],
})
export class OffsiteCertsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  disableflag: boolean = false;
  certificateQuota!: number;
  showZeroOverlay!: boolean;
  defaultSortColumn: string = 'dateCompleted';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
      { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
    ],
    openFilters: false,
    columns: [
      { header: 'Student Name', controlName: 'studentName' },
      { header: 'Student Id', controlName: 'studentId' },
      { header: 'Course Name', controlName: 'courseName'},
      { header: 'Training', controlName: 'training' },
      { header: 'Issue Date', controlName: 'issueDate', displayPipe: 'date' },
      { header: 'Expires', controlName: 'expiryDate', displayPipe: 'date' },
      { header: 'Certification Number', controlName: 'certificationName',
        actionColumnitems: [
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificationName' },
            buttonClass: (certificateName: string) => certificateName === 'Action Required' ? 'btn-warning' : 'btn-default'
          },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: false,
    displaySearch: false,
  };

  constructor(
    private snackBar: MatSnackBar,
    private offsiteCertService: OffsiteCertsService,
  ) {}

  ngOnInit(): void {
    this.fetchAll();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }
    const { search, fromDate, toDate, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : this.calculateDate(new Date(Number(new Date()) - 365 * 24 * 60 * 60 * 1000)),
      ToDate: toDate ? this.calculateToDate(toDate) : this.calculateToDate(new Date()),
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };
    var gridDatasource: any[] = [];
    this.offsiteCertService.getOffsiteCerts(params)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          this.gridOptions.isLoading = false;
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          return throwError(error);
        })).subscribe((data: any) => {
          gridDatasource = [];
          this.gridOptions.data.data = gridDatasource;
          if (data.rows.length > 0) {
            const { rows, totalNumberOfItems } = data;
            rows.forEach((row: any) => {
              const newData = row;
              const datasource = gridDatasource;
              gridDatasource.push(newData);
              gridDatasource = datasource;
              this.gridOptions.data.data = gridDatasource;
              this.gridOptions.totalNumberOfItems = totalNumberOfItems;
            });
          }
          this.gridOptions.isLoading = false;
        });
  }

  downloadCert(certId: number) {
    this.offsiteCertService.downloadOffsiteCert(certId).subscribe((response: any) => {
      const fileName = response.headers.get('content-disposition')?.split('filename=')[1].split(';')[0].replaceAll("\"", "");
      const blob = new Blob([response.body], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  calculateDate(val: any) {
    if (val) {
      return new Date(val).toISOString();
    }
  }

  calculateToDate(val: any) {
    if (val) {
      const date = new Date(val).toISOString();
      const newDate: any = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString();
    }
  }

  exportCsv(rawParams: any) {
    this.disableflag = true;
    const { search, fromDate, toDate, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDate ? this.calculateDate(fromDate) : '',
      ToDate: toDate ? this.calculateToDate(toDate) : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };
    this.offsiteCertService
      .exportOffsiteCerts(params)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          this.disableflag = false;
          return throwError(() => error);
        })
      )
      .subscribe(() => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "File is generated and emailed" }})
          this.disableflag = false;
        }
      );
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'studentName':
          activeSort = 'studentName';
          break;
        case sort.active === 'studentId':
          activeSort = 'studentId';
          break;
        case sort.active === 'courseName':
          activeSort = 'courseName';
          break;
        case sort.active === 'training':
          activeSort = 'training';
          break;
        case sort.active === 'issueDate':
          activeSort = 'dateCompleted';
          break;
        case sort.active === 'expiryDate':
          activeSort = 'expireDate';
          break;
        case sort.active === 'certificationName':
          activeSort = 'certificationName';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll(event.data);
    } else if (event.action === 'download') {
      this.downloadCert(event.data.id);
    } else if (event.action === 'addButton') {
      // add button
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    }
  }
}
