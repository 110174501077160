import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';

export interface BoxyMessageOptions {
  title: string,
  message: string,
  linkText: string,
  link: string,
  buttonText: string,
  disableButton: boolean,
}

@Component({
  selector: 'app-boxy-message',
  templateUrl: './boxy-message.component.html',
  styleUrls: ['./boxy-message.component.scss'],
  standalone: true,
  imports: [
    NgIf,
  ],
})

export class BoxyMessageComponent implements OnInit{
  @Output() buttonClicked = new EventEmitter<void>();
  @Input() boxyMessageOptions!: BoxyMessageOptions;
  title = '';
  message = '';
  linkText = '';
  link = '';
  buttonText = '';
  disableButton? = false;

  ngOnInit(): void {
    if (this.boxyMessageOptions) {
      this.title = this.boxyMessageOptions.title;
      this.message = this.boxyMessageOptions.message;
      this.linkText = this.boxyMessageOptions.linkText;
      this.link = this.boxyMessageOptions.link;
      this.buttonText = this.boxyMessageOptions.buttonText;
      this.disableButton = this.boxyMessageOptions.disableButton;
    }
    else {
      this.title = "Hi, I'm Boxy!";
      this.message = "It seems you haven\'t provided any content yet. You can pass in a BoxyMessageOptions object like this: {title: string, message: string, linkText: string, link: string, buttonText: string, disableButton: boolean}.";
      this.buttonText = "Button Text";
      this.linkText = "link here";
    }
  }

  onButtonClick() {
    this.buttonClicked.emit();
  }
}
