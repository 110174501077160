import { Component, Inject, OnInit  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ImportUsersComponent } from '../../../../routes/org/team/import-learners/import-learners-editor/import-users.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgIf, NgClass } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';

export type Mode = 'add' | 'edit' | 'delete';

export interface DialogData {
  mode: Mode;
  item?: any;
  canEdit?: boolean;
  deleteId?: any;
  itemName?: string;
  activeSubscription?: boolean;
  modalType?: string;
}

@Component({
  selector: 'app-import-users-modal',
  templateUrl: './import-users-modal.component.html',
  styleUrls: ['./import-users-modal.component.scss'],
  standalone: true,
  imports: [ModalLayoutComponent, NgIf, MatRadioModule, NgClass, ImportUsersComponent]
})
export class ImportUsersModalComponent {

  currentAddLearnerStep: number = 2;
  title = 'Upload Learners';
  constructor(
    private dialogRef: MatDialogRef<ImportUsersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private modalService: ModalService
  ) {}

  receiveDataFromChild(data: {currentAddLearnerStep: number, title: string}) {
    if (data.currentAddLearnerStep === 1 || data.currentAddLearnerStep === 3) {
      this.closeModel();
    }
    this.title = data.title;
  }

  closeModel() {
    this.dialogRef.close();
  }
}
