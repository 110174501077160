import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    return of(token).pipe(
      take(1),
      switchMap((jwt) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        return next.handle(request);
      })
    );
  }
}
