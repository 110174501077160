<div class="register-container mt-3">
  <h1 class="text-primary font-bold text-center">Register as an Organization Administrator</h1>
  <div class="pt-5">
    <p class="hidden sm:block text-center pb-5">
      You are registering an organization and managing training for a team. After creating an organization account, you will invite additional learners, assign courses, and monitor and report on their progress. Not managing a team? <a routerLink="/register/individual" class="primary-link">Register as an individual learner</a>.
    </p>
    <form [formGroup]="organization">
      <div class="flex mb-2 pl-5 pr-5">
        <h2 class="text-base font-bold inline-block">Organization Information</h2>
      </div>
      <div class="flex flex-wrap pl-5 pr-5 mb-5">
        <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
          <mat-form-field class="w-full">
            <mat-label>State the Organization is Headquartered</mat-label>
            <mat-select formControlName="stateId" panelClass="mat-select-mt3">
              <mat-option *ngFor="let state of this.lookupsStore.statesSignal()" [value]="state.key">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                organization.controls['stateId'].hasError('required') &&
                organization.controls['stateId'].touched
              ">Required</mat-error>
            <mat-error *ngIf="organization.controls['stateId'].errors?.message">{{
              organization.controls['stateId'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-full sm:w-1/2 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Industry</mat-label>
            <mat-select formControlName="industryId" panelClass="mat-select-mt3">
              <mat-option *ngFor="let industry of this.lookupsStore.industriesSignal()" [value]="industry.value">
                {{ industry.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="organization.controls['industryId'].errors?.message">{{
              organization.controls['industryId'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>

        <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Organization Name</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error *ngIf="
                organization.controls['name'].hasError('required') && organization.controls['name'].touched
              ">Required</mat-error>
            <mat-error
              *ngIf="organization.controls['name'].errors?.name && organization.controls['name'].touched">You
              must include a valid Organization Name.</mat-error>
            <mat-error *ngIf="organization.controls['name'].errors?.message">{{
              organization.controls['name'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-full sm:w-1/2 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Organization Website</mat-label>
            <input matInput type="text" formControlName="website" />
            <mat-error *ngIf="
                organization.controls['website'].hasError('required') &&
                organization.controls['website'].touched
              ">Required</mat-error>
            <mat-error *ngIf="
                organization.controls['website'].errors?.website && organization.controls['website'].touched
              ">You must include a valid Website.</mat-error>
            <mat-error *ngIf="organization.controls['website'].errors?.pattern">Please enter valid Website URL
            </mat-error>
            <mat-error *ngIf="organization.controls['website'].errors?.message">{{
              organization.controls['website'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="flex mb-2 pl-5 pr-5">
        <h3 class="text-base font-bold inline-block">Primary Account Admin Information</h3>
      </div>
      <div class="flex flex-wrap pl-5 pr-5 mb-5">
        <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
          <mat-form-field class="w-full">
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
            <mat-error *ngIf="
                organization.controls['firstName'].hasError('required') &&
                organization.controls['firstName'].touched
              ">Required</mat-error>
            <mat-error *ngIf="
                organization.controls['firstName'].errors?.firstName &&
                organization.controls['firstName'].touched
              ">You must include a valid First Name.</mat-error>
            <mat-error *ngIf="organization.controls['firstName'].errors?.message">{{
              organization.controls['firstName'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-full sm:w-1/2 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
            <mat-error *ngIf="
                organization.controls['lastName'].hasError('required') &&
                organization.controls['lastName'].touched
              ">Required</mat-error>
            <mat-error *ngIf="
                organization.controls['lastName'].errors?.lastName && organization.controls['lastName'].touched
              ">You must include a valid Last Name.</mat-error>
            <mat-error *ngIf="organization.controls['lastName'].errors?.message">{{
              organization.controls['lastName'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Email</mat-label>
            <input matInput type="text" (blur)="checkEmail($event.target.value, organization.controls['email'])"
              formControlName="email" />
            <mat-error *ngIf="
                organization.controls['email'].hasError('required') && organization.controls['email'].touched
              ">Required</mat-error>
            <mat-error
              *ngIf="organization.controls['email'].errors?.email && organization.controls['email'].touched">You
              must include a valid email.</mat-error>
          </mat-form-field>
          <div [ngClass]="{ 'mb-1 -mt-4 ': organization.controls['email'].errors?.message }">
            <mat-error *ngIf="organization.controls['email'].errors?.message">{{
              organization.controls['email'].getError('message')
              }}</mat-error>
          </div>
          <div [ngClass]="{ 'mb-1 -mt-4 ': organization.controls['email'].errors?.invite }">
            <mat-error class="text-danger support-text" *ngIf="organization.controls['email'].errors?.invite">
              {{ organization.controls['email'].getError('invite').message }}
              <br />
            </mat-error>
          </div>
        </div>
        <div class="w-full sm:w-1/2 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Confirm Email</mat-label>
            <input matInput type="text" formControlName="confirmEmail"  />
            <mat-error *ngIf="
                organization.controls['confirmEmail'].hasError('required') &&
                organization.controls['confirmEmail'].touched
              ">Required</mat-error>
            <mat-error *ngIf="
                organization.controls['confirmEmail'].errors?.notEquivalent &&
                organization.controls['confirmEmail'].touched
              ">Email must match.</mat-error>
            <mat-error *ngIf="organization.controls['confirmEmail'].errors?.message">{{
              organization.controls['confirmEmail'].getError('message')
              }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-full sm:w-1/2 pr-0 sm:pr-6 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Password</mat-label>
            <input matInput [type]="ohide ? 'password' : 'text'" formControlName="password" />
            <mat-icon matSuffix class="cursor-default eye-hidden" (click)="ohide = !ohide">{{
              ohide ? 'visibility' : 'visibility_off'
              }}</mat-icon>
            <mat-error *ngIf="
                organization.controls['password'].hasError('required') &&
                organization.controls['password'].touched
              ">Required
            </mat-error>
            <mat-error *ngIf="
                organization.controls['password'].errors?.password && organization.controls['password'].touched
              ">You must include a valid password.
            </mat-error>
          </mat-form-field>
          <div [ngClass]="{ 'mb-1 ': organization.controls['password'].errors?.message }">
            <mat-error *ngIf="organization.controls['password'].errors?.message">{{
              organization.controls['password'].getError('message')
              }}</mat-error>
          </div>
        </div>
        <div class="w-full sm:w-1/2 pb-2">
          <mat-form-field class="w-full">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="ochide ? 'password' : 'text'" formControlName="confirmPassword"
               />
            <mat-icon matSuffix class="cursor-default eye-hidden" (click)="ochide = !ochide">{{
              ochide ? 'visibility' : 'visibility_off'
              }}</mat-icon>
            <mat-error *ngIf="
                organization.controls['confirmPassword'].hasError('required') &&
                organization.controls['confirmPassword'].touched
              ">You must include confirm password.
            </mat-error>
            <mat-error *ngIf="
                organization.controls['confirmPassword'].errors?.notEquivalent &&
                organization.controls['confirmPassword'].touched
              ">Passwords do not match.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn-primary medium" [disabled]="disableFlag"
          id="CreateAccountOrganization" (click)="registerOrganization()">
          Create an Organization Account
        </button>
      </div>
    </form>


    <div class="text-center mt-4">
        <p class="large">Create an organization account if you're managing a team's training program. If you only need training for yourself, <a routerLink="/register/individual" class="primary-link underline">register as an individual learner</a>. You can share your certificate with your organization once your training is complete.</p>
    </div>

    <div class="mt-3">
      <p class="flex flex-wrap text-primary justify-center ">
        <span class="mr-1">By signing up, you accept our</span>
        <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
          class="primary-link mr-1 underline">Terms Of Use</a>
        <span class="mr-1">and</span>
        <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank"
          class="primary-link underline">Privacy Statement</a>
      </p>
      <p class="flex text-primary justify-center">
        <span class="mr-1">Already have an account?</span>
        <a href="/login" class="primary-link underline">Log In.</a>
      </p>
      <div class="mt-4 text-center">
        <p class="small">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" class="underline">Privacy Policy</a> and <a  class="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
      </div>
     
    </div>
  </div>
</div>