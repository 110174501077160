

import { Injectable, inject} from '@angular/core';
import { BaseAPI } from '../base-api';
import { TrainingMetricsStore} from './training-metrics.store';
import { tap } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class TrainingMetricsService extends BaseAPI{
   trainingMetricsStore = inject(TrainingMetricsStore);

  getTrainingMetricsReport(requestParams: any) {
    this.trainingMetricsStore.setLoading(true);
    const entityGuid: any = localStorage.getItem('entityGuid');
    requestParams.entityGuid = entityGuid;

    return this.http.get(`entityAdminReports/training-metrics`, { params: requestParams })
    .pipe(
      tap((res: any) => {
        this.trainingMetricsStore.setAll(res);
        this.trainingMetricsStore.setLoading(false);
      })
    );
  }
}


