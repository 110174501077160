import { Component, OnInit, OnDestroy, effect, inject, } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { OrganizationService } from 'src/app/api/services/organizations/organization.service';
import { AddUserGroupsComponent, DialogData } from '../../../../shared/components/modals/add-user-groups/add-user-groups.component';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { UserGroupsService } from 'src/app/api/services/userGroups/user-groups.service';
import { UserGroupsStore } from 'src/app/api/services/userGroups/user-groups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';

@Component({
    selector: 'app-user-groups',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        MRTGridTemplateComponent
    ],
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  readonly userGroupsStore = inject(UserGroupsStore);
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  showZeroOverlay!: boolean;
  defaultSortColumn: string = 'groupName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Course', formControlName: 'courseId', type: 'dropdown', lookup: this.lookupsStore.coursesSignal },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore.entityGroupStatusSignal },
    ],
    openFilters: false,
    columns: [
      { header: 'Group Name', controlName: 'name' },
      { header: 'Course', controlName: 'courses', displayPipe: 'joinList' },
      { header: 'Number of Learners', controlName: 'numberOfUsers',
        actionColumnitems: [
          { buttonAction: 'view', text: { calculateText: (numberOfUsers: any) => { return numberOfUsers > 0 ? numberOfUsers : '0'; }, 
          textColumn: 'numberOfUsers' }, disableOnColumnValue: {column: 'numberOfUsers', values: [0]} },
        ]
      },
      { header: 'Status', controlName: 'status' },
      { header: 'Edit', controlName: 'actions',
        actionColumnitems: [
          { icon: 'edit', buttonAction: 'edit' },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    displaySearch: false,
  };

  constructor(
    private userGroupsService: UserGroupsService,
    private organizationService: OrganizationService,
    private router: Router,
    private modalService: ModalService,
    private snackBar: MatSnackBar,
    public lookupsService: LookupsService,
  ) {
    effect(() => {
      if (!this.showZeroOverlay) {
        this.gridOptions.addButtonName = 'Add Groups';
      }
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.userGroupsStore.userGroupsSignal()?.rows ?? [];
      this.gridOptions.totalNumberOfItems = this.userGroupsStore.userGroupsSignal()?.totalNumberOfItems ?? 0;
    });
  }

  ngOnInit(): void {
    this.lookupsService.getCourses();
    this.lookupsService.getEntityGroupStatus()
    this.reloadOrgCourses();

    this.gridOptions.fetchData = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchAll(rawParams?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }

    const { status, search, courseId, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      Status: status ? status : '',
      CourseId: courseId ?? '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };
    this.userGroupsService.getUserGroups(entityGuid, params);
  }

  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'users-group';
    item?: any;
    deleteId?: any;
    itemName?: string;
  }) {
    const { mode, componentType, item, deleteId, itemName } = options;
    const data: DialogData = { mode, item, deleteId, itemName };
    if (componentType === 'users-group') {
      this.modalService.open(AddUserGroupsComponent, {
        modalType: ModalType.FullScreen,
        data,
      }, () => {
        const entityGuid = window.localStorage.getItem('entityGuid');
        if (!entityGuid) {
          return;
        }
        this.gridOptions.fetchData = true;
        this.lookupsService.getCourses();
      });
    }
  }

  goToGroup(row: any) {
    if (row.numberOfUsers > 0) {
      this.router.navigateByUrl(`/team/all-users?groupId=${row?.id}`);
    }
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'name':
          activeSort = 'groupName';
          break;
        case sort.active === 'courses':
          activeSort = 'courseName';
          break;
        case sort.active === 'numberOfUsers':
          activeSort = 'numberOfUsers';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  reloadOrgCourses() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
  
    this.organizationService.getOrgCourses(entityGuid);
  }
  
  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll();
    } else if (event.action === 'addButton') {
      this.openDialog({ mode: 'add', componentType: 'users-group' });
    }
    // 'edit' 'view' is specific to this component 
    else if (event.action === 'edit') {
      this.openDialog({ mode: 'edit', componentType: 'users-group', item: event.data });
    } else if (event.action === 'view') {
      this.goToGroup(event.data);
    }
  }
}
