import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UserInvitesService } from 'src/app/api/services/user-invites/user-invites.service';
import { environment } from 'src/environments/environment';
import { NgIf, TitleCasePipe, DatePipe } from '@angular/common';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@Component({
    selector: 'app-resend-invite-modal',
    templateUrl: './resend-invite-modal.component.html',
    styleUrls: ['./resend-invite-modal.component.scss'],
    standalone: true,
    imports: [
        ModalLayoutComponent,
        NgIf,
        TitleCasePipe,
        DatePipe,
        SnackbarComponent
    ],
})
export class ResendInviteModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<ResendInviteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInvitesService: UserInvitesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  close(success?: boolean) {
    this.dialogRef.close(success);
  }

  submitButton(button: any) {
    if (button === 'userInvite-resent') {
      const formValues = {
        inviteGuid: this.data?.items?.inviteGuid,
        acceptUrl: `${environment?.domainName}/accept-invitation-confirmed`,
      };
      this.userInvitesService
        .resendInvitation(formValues)
        .pipe(
          catchError((e) => throwError(() => e)),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: `Invitation Sent` }})
            this.close(true);
          },
          error: (e) => {
            console.log(e);
          },
        });
      return;
    } else if (button === 'userInvite-cancel') {
      this.userInvitesService
        .cancelInvite(this.data?.items?.inviteGuid)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: `Invitation Cancelled` }})
            this.close(true);
          },
          error: (e) => {
            console.log(e);
          },
        });
      return;
    }
  }
}
