import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImportUserService{
  constructor(private http: HttpClient) {}

  batchPreview(entityGuid: any, batch: any) {
    return this.http.post(`EntityAdminBatch/batch-user-invite/${entityGuid}`, batch);
  }

  batchInvite(batchId: any, isConfirmed: any) {
    return this.http.post(`EntityAdminBatch/user-invite/${batchId}/${isConfirmed}`, null);
  }
}
