  <div class="login flex justify-center px-3 lg:px-0 pb-5 lg:pb-5">

    <div class="lg:gap-16 flex site-container-1340 flex-col lg:flex-row items-center">
      <div class="w-full lg:w-1/2 mt-8 lg:mt-0">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="sm:p-5">
            <div>
              <div>
                <h1 class="mt-0 font-bold mb-3">Forgot Password?</h1>
              </div>
              <p class="text-primary ">
                Not to worry. Enter your email address and we'll send you a message with instructions to reset your password.
              </p>
  
              <div class="flex flex-col">
                <div class="forgot-psw-email">
                  <mat-form-field style="width: 100%">
                    <input matInput placeholder="Email address" type="email" formControlName="email" />
                    <mat-error
                      *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                      class="text-danger support-text"
                      >You must include an email address.</mat-error
                    >
                    <mat-error
                      *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched"
                      class="text-danger support-text"
                      >You must include a valid email address.</mat-error
                    >
                    <mat-error *ngIf="!verifyEmail && !inviteEmail && form.controls['email'].errors?.message">{{
                      form.controls['email'].getError('message')
                    }}</mat-error>
                  </mat-form-field>
                  
                  <div class="-mt-4 relative z-[1]" *ngIf="verifyEmail">
                    <mat-error>
                      You need to confirm your email.
                      <span class="underline cursor-pointer" (click)="resendEmail()">Resend Verification Email</span>
                    </mat-error>
                  </div>
                  
                  <div class="-mt-4 relative z-[1]" *ngIf="inviteEmail">
                    <mat-error class="text-danger support-text">
                      You have an open invitation.
                      <br/>
                      <span class="underline cursor-pointer" (click)="resendInvite(form.controls['email'].value)">Resend Invitation Email</span>
                    </mat-error>
                  </div>
                </div>
                <div class="text-center lg:text-left">
                  <button type="submit" class="btn-primary medium m-t-20" (click)="form.markAllAsTouched()" [disabled]="disableFlag">
                    Send Email
                  </button>
                </div>
              </div>
              
              <div class="mt-md mb-md forgot-psw-title text-center lg:text-left">
                <p class="">Return to 
                  <a routerLink="/login" class="primary-link underline">Log In</a>
                </p>
              </div>
            </div>
          </div>
        </form> 
      </div>
      <div class="w-full lg:w-1/2 mt-8 lg:mt-0">
        <img src="https://mrtstorageprod.blob.core.windows.net/site/boxster-forgot.webp" alt="two payment pages floating">
      </div>
    </div>
    
     
  </div>
