import { Component, OnInit, EventEmitter, Output, inject, computed } from '@angular/core';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';
import { SwitchEntityProfile } from '../../../../api/services/switch-entity/switch-entity-profile.model';
import { SwitchEntityProfileService } from '../../../../api/services/switch-entity/switch-entity-profile.service';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { OrganizationService } from 'src/app/api/services/organizations/organization.service';
import { TruncateTextPipe } from 'src/app/api/pipes/truncate-text/truncate-text.pipe';
import { map } from 'rxjs';
import { SwitchEntityProfileStore } from 'src/app/api/services/switch-entity/switch-entity-profile.store';
import { learnerMenuItems, orgAdminMenuItems, coopAdminMenuItems } from '../menu-items/menu-items';

export interface User {
  firstName: string;
  lastName: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
        MatIconModule,
        RouterLink,
        AsyncPipe,
        TruncateTextPipe
    ],
})
export class AppHeaderComponent implements OnInit {
  readonly switchEntityProfileStore = inject(SwitchEntityProfileStore);
  orgEntities: any[] = [];
  fontSize = 16;
  orgList: any = [];
  @Output() eventEmitter = new EventEmitter();

  coopListSignal = computed(() => this.switchEntityProfileStore.switchEntityProfileSignal()?.filter(
    org => this.isCoopAdmin(org) || org.entityType !== 'Coop'
  ));

  constructor(
    public authService: AuthenticationService,
    private switchEntityProfileService: SwitchEntityProfileService,
    private router: Router,
    public organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  switchEntityPro(org: SwitchEntityProfile) {
    this.switchEntityProfileService.updateSwitchOrganization(org.entityGuid);

    const orgAdminTopMenuItems = orgAdminMenuItems[0]?.state || '/dashboard';
    const coopAdminTopMenuItems = coopAdminMenuItems[0]?.state || '/coop/dashboard';
    const learnerTopMenuItems = learnerMenuItems[0]?.state || '/my-training';

    this.closeHeader();

    if (this.isEntityAdmin(org)) {
      this.router.navigate([orgAdminTopMenuItems]);
    } else if (this.isCoopAdmin(org)) {
      this.router.navigate([coopAdminTopMenuItems]);
    } else {
      this.router.navigate([learnerTopMenuItems]);
    }
  }

  isEntityAdmin(org: any): boolean {
    return org?.roles?.includes('EntityAdmin');
  }

  isCoopAdmin(org: any): boolean {
    return org?.roles?.includes('CoopAdmin');
  }

  closeHeader() {
    this.eventEmitter.emit();
  }
}
