import { Component, OnInit, OnDestroy, effect, inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrganizationService } from 'src/app/api/services/organizations/organization.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { SwitchEntityProfileStore } from 'src/app/api/services/switch-entity/switch-entity-profile.store';

@UntilDestroy()
@Component({
  selector: 'app-coop-association-modal',
  templateUrl: './coop-association-modal.component.html',
  styleUrls: ['./coop-association-modal.component.scss'],
  standalone: true,
  imports: [
      ModalLayoutComponent,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      SnackbarComponent
  ],
})
export class CoopAssociationModalComponent implements OnInit, OnDestroy {
  readonly switchEntityProfileStore = inject(SwitchEntityProfileStore);

  associationLink: any;
  form: FormGroup = this.fb.group({
    publicUserAssociationURL: this.fb.control(''),
  });
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<CoopAssociationModalComponent>,
    private organizationService: OrganizationService,
  ) {}

  ngOnInit(): void {
    this.loadOrg();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clipBoard() {
      this.clipboard.copy(`${this.associationLink}`);
      this.closeModal();
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "Copied Link" }})
  }

  closeModal() {
    this.dialogRef.close();
  }

  loadOrg() {
    let entityGuid: any = '';
    if (window.localStorage.getItem('entityGuid')) {
      entityGuid = window.localStorage.getItem('entityGuid');
    }

    effect(() => {
      const activeProfile = this.switchEntityProfileStore.activeProfileSignal();

      if (activeProfile) {
        this.organizationService.getOrg(entityGuid).subscribe((data: any) => {
          this.associationLink = data?.landingPageShortUrl;
          this.form.get('publicUserAssociationURL')?.setValue(this.associationLink);
        });
      }
    });
  }
}
