<app-modal-layout>
  <div title>
    <ng-container *ngIf="mode === 'add'">Add Group</ng-container>
    <ng-container *ngIf="mode === 'edit'">Edit Group</ng-container>
  </div>
  <form [formGroup]="addGroups">
    <div class="flex flex-wrap">
      <div class="w-1/2">
        <mat-form-field class="w-full p-2">
          <mat-label>Group Name</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error *ngIf="addGroups.controls['name'].hasError('required') && addGroups.controls['name'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="addGroups.controls['name'].errors?.message">{{
            addGroups.controls['name'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-1/2">
        <mat-form-field class="w-full p-2">
          <mat-label>Status</mat-label>
          <mat-select formControlName="isActive" panelClass="mat-select-mt3">
            <mat-option *ngFor="let status of this.lookupsStore.entityGroupStatusSignal()" [value]="status.value">
              {{ status.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="addGroups.controls['isActive'].hasError('required') && addGroups.controls['isActive'].touched">
            Required
          </mat-error>
          <mat-error *ngIf="addGroups.controls['isActive'].errors?.message">{{
            addGroups.controls['isActive'].getError('message')
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full p-2">
        <h2 class="text-base font-bold">Number of Learners</h2>
        <div class="flex">
          <div class="flex" *ngIf="mode === 'edit'">
            <span class="mr-4"> {{ groupListNumber }} </span>
            <a (click)="goToGrid()" *ngIf="groupListNumber !== 0" class="cursor-pointer primary-link">View
              List</a>
          </div>
          <div *ngIf="mode === 'add'">No Learners Assigned</div>
        </div>
      </div>
    </div>
    <div class="p-2">
      <h2 class="text-base font-bold">Trainings</h2>
      <p class="text-base text-left">
        Click on a course card below to select the course you would like to assign to the group.
      </p>
    </div>
    <div class="lg:p-5">
      <div class="grid grid-rows-columns lg:ml-auto lg:mr-auto justify-center gap-8">
        <div
          class="w-full h-full transform transition lg:hover:scale-110"
          *ngFor="let course of this.organizationStore.activeCoursesSignal()"
        >
          <div class="flex flex-col h-full">
            <div
              class="p-3 flex-1 sm:transform sm:transition mobile-choose-course flex items-center justify-center bg-color-lightBlue py-lg text-center cursor-pointer relative choose-course h-full mx-auto sm:mx-0 w-full"
              [ngClass]="
                course?.groupCourse
                  ? 'course-active-element disabled'
                  : ''
              "
              (click)="selectCourse(course)"
            >
            <div [ngClass]="course?.groupCourse ? ' text-white' : 'text-primary'">
              <div>
                <div *ngIf="course?.isCE" class="flex text-left justify-start items-center">
                  <p class="small">
                    <mat-icon class="round-active-text font-weight-bold leading-none"
                      [ngClass]="course?.groupCourse ? 'check-icon-text-outline text-secondary': 'check-icon-text-filled text-primary'"
                    >done</mat-icon>
                    <span [ngClass]="course?.groupCourse ? ' text-white' : 'text-primary'">CE Approved</span>
                  </p>
                </div>
              </div>
              <div class="flex">
                <p class="bold"
                [ngClass]="course?.groupCourse ? ' text-white' : ''">
                  {{ course?.trainingHeader}}
                  {{ course?.state !== 'NATL' ? ' | ' + course?.state : ''}}
                </p>
              </div>
              <h2 class="learner leading-none font-semibold capitalize text-left"
                [ngClass]="course?.groupCourse ? ' text-white' : ''">{{ course.name }}</h2>
              <p class="text-left mb-0 mt-3" [ngClass]="course?.groupCourse ? ' text-white' : ''"
                >{{course?.moduleNames.length}} {{course?.moduleNames.length !== 1 ? 'Modules:': 'Module:'}} {{course?.moduleNames.join(', ')}}</p>
              <mat-icon
                class="check-icon-text-filled round-active-icon-text font-weight-bold leading-none text-primary"
              >
                done
              </mat-icon>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2">
      <p class="text-base mb-8 text-left">
        Please note: Only Trainings added to the Organization are available for Group Assignment. To search for more trainings,
        please add in <a (click)="goToOrg()" class="cursor-pointer primary-link">Organization Trainings</a>
      </p>
    </div>
  </form>
  <div class="text-center">
    <button class="btn-primary large" [disabled]="disableFlag" (click)="onSubmitGroups()">
      <ng-container *ngIf="mode === 'add'">Add Group</ng-container>
      <ng-container *ngIf="mode === 'edit'">Edit Group</ng-container>
    </button>
  </div>
</app-modal-layout>