<app-modal-layout>
  <div title>
    {{
    data?.buttonAction === 'userall-confirmEmail'
    ? 'Confirm Email'
    : data?.buttonAction === 'userInvite-resent'
    ? 'Resend Invitation'
    : data?.buttonAction === 'userInvite-cancel'
    ? 'Cancel Invitation'
    : ''
    }}
  </div>
  <p *ngIf="data?.buttonAction === 'userall-confirmEmail'">
    Are you sure you want to confirm the email <strong>{{ data?.items?.email }}?</strong>
  </p>
  <p *ngIf="data?.buttonAction === 'userInvite-resent'">
    Learner was originally invited to <strong>{{ data?.items.organizationName | titlecase }}</strong> on
    {{ data?.items?.invitedDate | date : 'MM/dd/yy' }}. To resend the invitation email to
    <strong>{{ data?.items?.email }}</strong>, click the Resend Invite button.
  </p>
  <p *ngIf="data?.buttonAction === 'userInvite-cancel'">
    Learner was originally invited to <strong>{{ data?.items.organizationName | titlecase }}</strong> on
    {{ data?.items?.invitedDate | date : 'MM/dd/yy' }}. To cancel this invitation (and disable link in previously sent
    emails), click the Cancel
    Invite button.
  </p>

  <div class="text-center mt-4">
    <button class="btn-primary large" (click)="submitButton(data?.buttonAction)">
      {{
      data?.buttonAction === 'userall-confirmEmail'
      ? 'Confirm Email'
      : data?.buttonAction === 'userInvite-resent'
      ? 'Resend Invite'
      : data?.buttonAction === 'userInvite-cancel'
      ? 'Cancel Invite'
      : ''
      }}
    </button>
  </div>

</app-modal-layout>