<main>
  <ng-container *ngIf="this.switchEntityProfileStore.activeProfileSignal(); let activeOrg">
    <div class="page-wrapper">
      <div class="header-container">
        <div>
          <app-banner (isEnvBanner)="isEnvBanner($event)"></app-banner>
        </div>
        <div class="h-16">
          <div class="header flex justify-between h-16">
            <div class="flex items-center">
              <button
                *ngIf="!mobileQuery.matches"
                class="text-primary menu-icon flex items-center mr-5"
                (click)="sideNav.toggle(); closeProfileNav()"
              >
                <mat-icon>menu</mat-icon>
              </button>
              <div *ngIf="!mobileQuery.matches">
                <a [href]="isEntityAdmin(activeOrg)  ? '/dashboard' : '/my-training'" class="header-logo-icon regular-course-icon">
                  <img src="assets/MandatedReporterTraining-icon.svg" alt="Mandated Reporter Training" />
                </a>
              </div>
              <div *ngIf="mobileQuery.matches">
                <a  [href]="isEntityAdmin(activeOrg)  ? '/dashboard' : '/my-training'" class="header-logo regular-course-icon">
                  <img src="assets/MandatedReporterTraining-logo.svg" alt="Mandated Reporter Training" />
                </a>
              </div>
            </div>


            <div class="flex items-center max-h-9" *ngIf="!isAdmin">
              <div class="flex items-center" (click)="profileSnav.toggle(); closeSideNav()">

                <div
                 *ngIf="mobileQuery.matches" class="flex flex-col items-end">
                  <p class="m-0 p-0 bold">{{ activeOrg!.entityName | truncateText: 25}}</p>
                  <p class="role-tag"> {{ this.organizationService.getOrgChipLabel(activeOrg) }}</p>
                </div>
                <div class="profile-img ml-2 cursor-pointer" >
                  <div *ngIf="!activeOrg.logoImageUrl" class="h-10 w-10">
                    <img
                      *ngIf="activeOrg!.entityType == 'Individual'"
                      src="assets/images/profile/individual-icon.svg"
                      alt="Default profile picture for individual"
                    />
                    <img
                      *ngIf="activeOrg!.entityType == 'Organization'"
                      src="assets/images/profile/organization-icon.svg"
                      alt="Default profile picture for organization"
                    />
                    <img
                      *ngIf="activeOrg!.entityType == 'Coop'"
                      src="assets/images/profile/organization-icon.svg"
                      alt="Default profile picture for cooperative"
                    />
                  </div>
                  <img *ngIf="activeOrg.logoImageUrl" [src]="activeOrg.logoImageUrl" alt="Profile Picture" />
                </div>
  
                <div class="pl-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 417.9l17-17L465 209l17-17L448 158.1l-17 17-175 175L81 175l-17-17L30.1 192l17 17L239 401l17 17z"/></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-site">
        <mat-sidenav-container class="h-full" *ngIf="!isAdmin">
          <mat-sidenav
            #snav
            id="snav"
            [mode]="mobileQuery.matches ? 'side' : 'over'"
            [opened]="sidebarOpened"
            (opened)="sidebarOpened = true"
            (close)="sidebarOpened = false"
            (closed)="sidebarOpened = false"
            position="start"
            [autoFocus]="false"
          >
            <app-sidebar class="app-sidebar pt-3" (eventEmitter)="closeSideNav()"></app-sidebar>
            <div class="relative text-center w-full py-3">
              <p class="small version w-full">v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }}</p>
            </div>
          </mat-sidenav>
          <mat-sidenav [autoFocus]="false" [ngClass]="!isProd? 'dev-banner' : ''" #profileSnav id="profile-snav" mode="over" position="end" width="248px">
            <app-header (eventEmitter)="closeProfileNav()"></app-header>
          </mat-sidenav>
          <mat-sidenav-content id="scrolldiv" [ngClass]="{ minitoggle: !snav.opened }">
            <div class="flex flex-col h-full">
              <div *ngIf="isEntityAdmin(activeOrg) && isOrgPage()">
                <app-org-admin-banner></app-org-admin-banner>
              </div>
              <app-breadcrumb></app-breadcrumb>
              <div class="flex-grow">
                <router-outlet>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
              <div>
                <app-footer></app-footer>
              </div>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>
    
    <ng-container *ngIf="startTourServiceFlag">
      <ngx-guided-tour
        nextText="Next Tip "
        progressIndicator="template"
        progressIndicatorLocation="progressIndicatorLocation"
        skipText="Click here to skip"
        doneText="Finish"
        tourStepWidth="500"
      >
      </ngx-guided-tour>
    </ng-container>
    
  </ng-container>
</main>