<div class="content-container no-data-overlay-parent">
    <div class="flex justify-between items-center">
      <h3 class="mr-3">Plan Details</h3>
      <div *ngIf="hasSubscription">
        <button
          *ngIf="!isEnterprise"
          class="btn-primary-outlined medium mr-0"
          (click)="manageSubscription()"
          [disabled]="disableManageButton"
        >
          Manage Subscription
        </button>
        <a
          *ngIf="isEnterprise"
          class="btn-primary-outlined medium mr-0"
          href="https://mandatedreportertraining.com/sales/"
          target="_blank"
        >
          Contact Us
        </a>
      </div>
      <div *ngIf="!hasSubscription">
        <button class="btn-primary-outlined medium mr-0" (click)="openPricingModal()">Subscribe Now</button>
      </div>
    </div>
    <div class="mt-4">
      <div class="grid grid-cols-1">
        <div class="subscription-column">
          <div class="subscription-label">Current Plan</div>
          <div>{{ subscription?.accountBillingOverview.planName ?? '-' }}</div>
        </div>
        <div class="subscription-column" *ngIf="subscription?.accountBillingOverview?.planType == 'SeatBased'">
          <div class="subscription-label">Active Users / Plan</div>
          <div *ngIf="subscription?.accountBillingOverview?.amountOverQuota == 0">
            {{ subscription?.accountBillingOverview?.seatsUsedSubscription }} / {{ subscription?.accountBillingOverview?.seatSubscriptionQuota}}
          </div>
          <div *ngIf="subscription?.accountBillingOverview?.amountOverQuota > 0">
            <a style="color: red">{{ subscription?.accountBillingOverview?.seatsUsedSubscription}} / {{ subscription?.accountBillingOverview?.seatSubscriptionQuota}}</a>
          </div>
        </div>
        <div class="subscription-column" *ngIf="subscription?.accountBillingOverview?.planType == 'CertificateBased'">
          <div class="subscription-label">Certificates Issued / Plan</div>
          <div *ngIf="subscription?.accountBillingOverview?.amountOverQuota == 0">
            {{ subscription?.accountBillingOverview?.certificatesUsedSubscription }} / {{ subscription?.accountBillingOverview?.certificateSubscriptionQuota }}
          </div>
          <div *ngIf="subscription?.accountBillingOverview?.amountOverQuota > 0">
            <a style="color: red">{{ subscription?.accountBillingOverview?.certificatesUsedSubscription }} / {{ subscription?.accountBillingOverview?.certificateSubscriptionQuota }}</a>
          </div>
        </div>
        <div class="subscription-column">
          <div class="subscription-label">Status</div>
          <div>{{subscription?.cancelOnDate? 'Cancelled' : subscription?.status ?? '-' }}</div>
        </div>
        <div class="subscription-column">
          <div class="subscription-label">Next Billing</div>
          <div>{{ subscription?.cancelOnDate ?? subscription?.nextBilling ?? '-' }}</div>
        </div>
        <div class="subscription-column">
          <div class="subscription-label">Payment Method</div>
          <div>{{ subscription?.paymentMethod ?? '-' }}</div>
        </div>
        <div class="subscription-column">
          <div class="subscription-label">Billing Zip</div>
          <div>{{ subscription?.billingZip ?? '-' }}</div>
        </div>
        <div class="subscription-column">
          <div class="subscription-label">Billing Email</div>
          <div>{{ subscription?.billingEmail ?? '-' }}</div>
        </div>
      </div>
    </div>
  
</div>
  
<div class="content-container  mt-8" *ngIf="subscription?.accountBillingOverview?.planType == 'CertificateBased'">
    <div class="flex justify-between items-center">
        <h3 class="mr-3">Pay-As-You-Go Order History</h3>
        <button class="btn-primary-outlined medium" (click)="openPayAsYouGoTab()"  *ngIf="!isEnterprise">Purchase Certificates</button>    
    </div>
    <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
</div>