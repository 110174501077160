<div class="flex flex-col justify-between" style="height: 100dvh">
  <div>
    <mat-toolbar class="auth-header">
      <div class="w-full">
        <div class="flex justify-between items-center">
          <div class="logo">
            <a href="https://mandatedreportertraining.com/" class="header-logo regular-course-icon">
              <img src="assets/MandatedReporterTraining-logo.svg" alt="Mandated Reporter Training" />
            </a>
          </div>
        </div>
      </div>
    </mat-toolbar>
    <ng-container *ngIf="errorType === '404 Error'; else otherErrors">
      <div class="items-center flex pt-10">
        <div class="w-full h-full flex justify-center">
          <div class="p-5 sm:w-4/5">
            <div class="p-5">
              <div class="justify-center" >
                <div class="text-center">
                  <h1 class="m-t-0 text-primary  font-bold mb-3 ">
                    Like unicorns, this page no longer exists.
                  </h1>
                  <h2 class="m-t-0 learner font-bold text-black mb-3 ">{{errorType}}</h2>
                </div>
                <div id="animation-container" width="width: 50vw; height: 30vw;"></div>
                <h4 class="text-primary text-center mb-4 w-full lg:w-1/2 mx-auto" >
                  But, like unicorns, it holds a touch of magic and can guide you wherever you want! Hit the Return Home button to navigate to wherever you wish to go.
                </h4>
              <div class="flex justify-center items-center text-center">
                <button type="submit" (click)="goToHome()" class="btn-primary large m-t-20">
                  Return to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #otherErrors>
    <div class="items-center flex pt-10">
      <div class="w-full h-full flex justify-center">
        <div class="p-5 sm:w-4/5">
          <div class="p-5">
            <div class="justify-center">
              <div class="text-center">
                <h1 class="m-t-0 text-primary  font-bold mb-3 ">
                  {{title}}
                </h1>
                <h2 class="m-t-0 learner font-bold text-black mb-3 ">{{errorType}}</h2>
              </div>
              <div  class="flex justify-center">
                <div class="block max-w-md">
                  <p class="text-primary text-center text-base mb-4 font-semibold">
                    If you would like to join this Organization please contact the Organization Administrator. 
                  </p>
                  <p class="text-primary text-center text-base mb-4 font-semibold">
                    If you are the Organization Administrator, adjust this setting in your organization profile. 
                  </p> 
                </div>
              </div>

              <div class="flex justify-center items-center text-center">
                <button type="submit" (click)="goToHome()" class="btn-primary large m-t-20">
                  Return to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  </div>
  <app-footer></app-footer>
</div>
