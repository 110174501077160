import { AfterViewInit, Component, effect, inject, OnDestroy, OnInit, computed } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoopAssociationModalComponent } from 'src/app/shared/components/modals/coop-association-modal/coop-association-modal.component';
import { AddLearnersComponent, DialogData } from 'src/app/shared/components/modals/add-learners/add-learners.component';
import { ResendInviteModalComponent } from 'src/app/shared/components/modals/resend-invite-modal/resend-invite-modal.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LearnersService } from 'src/app/api/services/learners/learners.services';
import { LearnersStore } from 'src/app/api/services/learners/learners.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { SubscriptionStore } from 'src/app/api/services/subscription/subscription.store';
import { SwitchEntityProfileStore } from 'src/app/api/services/switch-entity/switch-entity-profile.store';

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
    MRTGridTemplateComponent
  ],
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly switchEntityProfileStore = inject(SwitchEntityProfileStore);
  readonly subscriptionStore = inject(SubscriptionStore);
  readonly learnersStore = inject(LearnersStore);
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  activeSubscription!: boolean;
  showZeroOverlay!: boolean;
  defaultSortColumn: string = 'invitedDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Group', formControlName: 'groupId', type: 'dropdown', lookup: this.lookupsStore.groupsSignal },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: [
        {value: 'Active', label: 'Active'}, {value: 'Archived', label: 'Archived'}, {value: 'Cancelled', label: 'Cancelled'},
        {value: 'Declined', label: 'Declined'}, {value: 'Inactive', label: 'Inactive'}, {value: 'Pending', label: 'Pending'},
        
      ]},
    ],
    openFilters: false,
    columns: [
      { header: 'Invited Date', controlName: 'invitedDate', displayPipe: 'date' },
      { header: 'First Name', controlName: 'first' },
      { header: 'Last Name', controlName: 'last' },
      { header: 'Email', controlName: 'email' },
      { header: 'Groups', controlName: 'groups' },
      { header: 'Associated Date', controlName: 'associatedDate', displayPipe: 'date'},
      { header: 'Status', controlName: 'status' },
      { header: 'Actions', controlName: 'actions',
        actionColumnitems: [
          {
            icon: 'edit', buttonAction: 'edit',
            hideOnColumnValue: {column: 'status', values: ['Pending', 'Declined', 'Cancelled']}
          },
          {
            icon: 'refresh', buttonAction: 'resend',
            disableOnColumnValue: {column: 'status', values: ['Accepted']},
            hideOnColumnValue: {column: 'status', values: ['Active', 'Inactive', 'Archived']}
          },
          {
            icon: 'cancel', buttonAction: 'cancel',
            disableOnColumnValue: {column: 'status', values: ['Accepted', 'Cancelled', 'Declined']},
            hideOnColumnValue: {column: 'status', values: ['Active', 'Inactive', 'Archived']}
          },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: false,
    displaySearch: false,
  };

  entityTypeSignal = computed(() => this.switchEntityProfileStore.switchEntityProfileSignal()?.find((item: any) => item.selected === true));

  constructor(
    private learnersService: LearnersService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public lookupsService: LookupsService,
  ) {
    effect(() => {
      if (!this.showZeroOverlay) {
        this.gridOptions.addButtonName = 'Add Learners';
      }
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.learnersStore.learnersSignal()?.rows ?? [];
      this.gridOptions.totalNumberOfItems = this.learnersStore.learnersSignal()?.totalNumberOfItems ?? 0;
    });
  }

  ngOnInit(): void {
    const subscription = this.subscriptionStore.subscriptionSignal();
    if (subscription?.hasActiveSubscription != null && subscription?.hasActiveSubscription != undefined) {
      this.activeSubscription = subscription.hasActiveSubscription;
    }



    this.lookupsService.getOrgRoles();
    this.lookupsService.getGroups();
    this.lookupsService.getEntityUserStatus();
    this.gridOptions.fetchData = true;
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['groupId']) {
        const id = +params['groupId'];
        this.gridOptions.filterData.find((x) => x.formControlName === 'groupId')!.defaultValue = id;
        this.gridOptions.fetchData = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchAll(rawParams?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }

    this.gridOptions.isLoading = true;
    if (!rawParams) {
      rawParams = { pageSize: 20, pageNumber: 1 };
    }
    const { search, groupId, status, role, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length > 0 ? search : '',
      GroupId: groupId ? [groupId] : [],
      Status: status ?? '',
      EntityGuid: entityGuid,
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

  
    this.learnersService.getUsers(params);
  }

  clearFilters() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
  }

  exportCsv(rawParams?: any) {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    const { search, groupId, status, role } = rawParams;
    const params = {
      SearchTerm: search?.length > 0 ? search : '',
      GroupId: groupId ? [groupId] : [],
      OrgRoleId: role ?? '',
      Status: status ?? '',
      EntityGuid: entityGuid,
    };

    this.learnersService.getAllExcelUsers(params);
  }

  goToEditUser(row: any) {
    const entityType = window.localStorage.getItem('entityType');
    if (entityType === 'Coop') {
      this.router.navigateByUrl(`/coop/edit-user?userId=${row.entityUserId}`).then();
    } else {
      this.router.navigateByUrl(`/team/edit-users?userId=${row.entityUserId}`).then();
    }
  }

  sortField(sort?: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'invitedDate':
          activeSort = 'invitedDate';
          break;
        case sort.active === 'associatedDate':
          activeSort = 'associatedDate';
          break;
        case sort.active === 'first':
          activeSort = 'firstName';
          break;
        case sort.active === 'last':
          activeSort = 'lastName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'groups':
          activeSort = 'groupName';
          break;
        case sort.active === 'roles':
          activeSort = 'orgRole';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  openCoopAssociationModal() {
    this.modalService.open(CoopAssociationModalComponent, {
      modalType: ModalType.Medium,
    });
  }

  addOrgLearners(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'users';
    item?: any;
    deleteId?: any;
    itemName?: string;
    activeSubscription?: boolean;
    modalType?: string;
  }) {
    const { mode, componentType, item, deleteId, itemName, activeSubscription = this.activeSubscription, modalType } = options;
    const data: DialogData = { mode, item, deleteId, itemName, activeSubscription, modalType };
    if (componentType === 'users') {
      this.modalService.open(
        AddLearnersComponent,
        {
          modalType: ModalType.FullScreen,
          data,
        },
        (result) => {
          if (result) {
            this.gridOptions.fetchData = true
          }
        },
      );
    }
  }

  openEditInviteModal(row: any, action: any) {
    if (action === 'userInvite-resent' || 'userInvite-cancel') {
      this.modalService.open(
        ResendInviteModalComponent,
        {
          modalType: ModalType.Medium,
          data: {
            buttonAction: action,
            items: row,
          },
        },
        (response?) => {
          if (response) {
            this.gridOptions.fetchData = true
          }
        },
      );
    }
  }

  actionEvent(event: any) {
    const entityType = window.localStorage.getItem('entityType');
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv(event.data);
    } else if (event.action === 'formChanges') {
      this.fetchAll(event.data);
    } else if (event.action === 'clearFilters') {
      this.clearFilters();
    } else if (event.action === 'addButton') {
      if (entityType === 'Coop') {
        this.openCoopAssociationModal();
      } else if (entityType === 'Organization') {
        this.addOrgLearners({ mode: 'add', componentType: 'users', modalType: 'add' });
      }
    }
    // 'edit' & 'resend' & 'cancel' is specific to this component 
    else if (event.action === 'edit') {
      this.goToEditUser(event.data);
    } else if (event.action === 'resend') {
      this.openEditInviteModal(event.data, 'userInvite-resent');
    } else if (event.action === 'cancel') {
      this.openEditInviteModal(event.data, 'userInvite-cancel');
    }
  }
}
