import { Component, OnInit, Inject, OnDestroy, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InviteAcceptConfirmedService } from 'src/app/api/services/invite-accept-confirmed/invite-accept-confirmed.service';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';
import { SwitchEntityProfileService } from '../../../../api/services/switch-entity/switch-entity-profile.service';
import { ModalLayoutComponent } from '../modal-layout/modal-layout.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-accept-invitemodal',
    templateUrl: './accept-invitemodal.component.html',
    styleUrls: ['./accept-invitemodal.component.scss'],
    standalone: true,
    imports: [ModalLayoutComponent, SnackbarComponent, NgIf],
})
export class AcceptInvitemodalComponent implements OnInit, OnDestroy {
  orgName!: string;
  inviteOrgGuid!: string;
  entityGuid: any;
  acceptGuid: any;
  acceptSub!: Subscription;
  declineSub!: Subscription;
  disableFlag: boolean = false;
  disableFlag1: boolean = false;
  isLoading: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<AcceptInvitemodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthenticationService,
    private inviteConfirmedService: InviteAcceptConfirmedService,
    private switchEntityProfileService: SwitchEntityProfileService,
  ) {}

  ngOnInit(): void {
    this.orgName = this.data?.item?.inviteOrgName;
    this.inviteOrgGuid = this.data?.item?.inviteOrgGuid;
  }
  onAccept() {
    this.disableFlag = true;
    this.acceptSub = this.inviteConfirmedService
      .inviteResponse(this.data?.item?.inviteGuid, true)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((res) => {
          this.acceptGuid = res;
          // reload the user to get the new orgAndRoles and switch to the new org
          this.authService.loadUserDetails().pipe(take(1)).subscribe(() => {
            this.switchEntityProfileService.updateSwitchOrganization(this.acceptGuid);
          });
        }),
      )
      .subscribe({
        next: () => {
          this.inviteConfirmedService.inviteInfo$.next(null);
          this.disableFlag = false;
          this.dialogRef.close(true);
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Success, message: "Invitation accepted" }
          });
          this.router.navigateByUrl('/');
        },
        error: (e) => {
          if (e?.error?.inviteGuid[0] === 'Invite has already been responded to') {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Success, message: "Invite has already been responded to" }
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: "Something Went Wrong" }
            });
          }
          this.dialogRef.close();
          this.disableFlag = false;
        }
      });
      
  }

  onDeclined() {
    this.disableFlag1 = true;
    this.declineSub = this.inviteConfirmedService
      .inviteResponse(this.data?.item?.inviteGuid, false)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe(
        () => {
          this.inviteConfirmedService.inviteInfo$.next(null);
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Invitation Declined' }})
          this.dialogRef.close();
          this.disableFlag1 = false;
        },
        (e) => {
          if (e?.error?.inviteGuid[0] === 'Invite has already been responded to') {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Invite has already been responded to' }})
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Something Went Wrong" }})
          }
          this.router.navigateByUrl('/').then(() => {
            this.router.navigate([`/training`], {
              queryParams: { invite: null, inviteOrgGuid: null, inviteGuid: null, welcomeModal: null },
            });
          });
          this.dialogRef.close();
          this.disableFlag1 = false;
        },
      );
  }

  ngOnDestroy(): void {
    if (this.acceptSub) {
      this.acceptSub?.unsubscribe();
    }
    if (this.declineSub) {
      this.declineSub?.unsubscribe();
    }
  }

  closeModel() {
    this.onDeclined();
  }
}
