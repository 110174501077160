<h1 class="learner pl-4 pt-4">{{ userName }}</h1>
<div class="back-to-link mb-4 lg:mb-0 pr-4">
  <a class="primary-link" routerLink="/team/all-users">Back to All Learners</a>
</div>
<mat-tab-group #tabs>
  <mat-tab label="Information">
    <ng-container *ngTemplateOutlet="step1"></ng-container>
  </mat-tab>
  <mat-tab *ngIf="this.switchEntityProfileStore.activeProfileSignal()?.entityType !== 'Coop'" label="Trainings">
    <ng-container *ngTemplateOutlet="step2"></ng-container>
  </mat-tab>
</mat-tab-group>
<ng-template #step1>
  <form [formGroup]="addUsers">
    <!-- TODO: Verify appearance outlined is what we want -->
    <mat-card appearance="outlined" class="mb-10">
      <mat-card-content>
        <mat-toolbar class="bg-transparent h-12 px-4 text-black flex justify-between text-base font-bold w-full">
          <span>User Information</span>
        </mat-toolbar>
        <div class="flex flex-wrap justify-between sm:mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full">
              <mat-label>First Name</mat-label>
              <input type="text" matInput formControlName="firstName" />
              <mat-error
                *ngIf="addUsers.controls['firstName'].hasError('required') && addUsers.controls['firstName'].touched"
              >
                Required
              </mat-error>
              <mat-error *ngIf="addUsers.controls['firstName'].errors?.message">{{
                addUsers.controls['firstName'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2">
            <mat-form-field class="w-full">
              <mat-label>Last Name</mat-label>
              <input type="text" matInput formControlName="lastName" />
              <mat-error
                *ngIf="addUsers.controls['lastName'].hasError('required') && addUsers.controls['lastName'].touched"
              >
                Required
              </mat-error>
              <mat-error *ngIf="addUsers.controls['lastName'].errors?.message">{{
                addUsers.controls['lastName'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input type="text" matInput formControlName="email"  />
              <mat-error *ngIf="addUsers.controls['email'].hasError('required') && addUsers.controls['email'].touched">
                Required
              </mat-error>
              <mat-error *ngIf="addUsers.controls['email'].errors?.email && addUsers.controls['email'].touched"
                >You must include a valid email.
              </mat-error>
              <mat-error *ngIf="addUsers.controls['email'].errors?.message">{{
                addUsers.controls['email'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2" *ngIf="this.switchEntityProfileStore.activeProfileSignal().entityType !== 'Coop'">
            <p class="font-bold mb-1">Password</p>
            <a
              (click)="openDialog({ mode: 'add', componentType: 'reset-password' })"
              class="underline primary-link cursor-pointer"
              >Reset Password</a
            >
          </div>
        </div>
        <mat-toolbar
          class="bg-transparent whitespace-pre-wrap h-12 px-4 text-black flex justify-between text-base font-bold w-full"
        >
          <span>Organization Information</span>
        </mat-toolbar>
        <div class="flex flex-wrap justify-between sm:mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" panelClass="mat-select-mt3" >
                <mat-option *ngFor="let status of this.lookupsStore.statusSignal()" [value]="status.value"
                  >{{ status.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="addUsers.controls['status'].hasError('required') && addUsers.controls['status'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="addUsers.controls['status'].errors?.message">{{
                addUsers.controls['status'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2" *ngIf="this.switchEntityProfileStore.activeProfileSignal()?.entityType !== 'Coop'">
            <app-form-select label="Group" multiple="true" formControlName="entityGroupIds" [options]="this.lookupsStore.groupsSignal()">
            </app-form-select>
          </div>
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full">
              <mat-label>Roles</mat-label>
              <mat-select [multiple]="true" formControlName="roles" panelClass="mat-select-mt3">
                <mat-option
                  value="{{ option.value }}"
                  *ngFor="let option of this.lookupsStore.rolesSignal()"
                  [disabled]="option.label === 'Learner'"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="addUsers.controls['roles'].hasError('required') && addUsers.controls['roles'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="addUsers.controls['roles'].errors?.message">{{
                addUsers.controls['roles'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="btn-primary medium"
            [disabled]="disableFlag"
            (click)="update()"
          >
            Update User
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</ng-template>
<ng-template #step2>
      <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
</ng-template>
