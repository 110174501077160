import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LocalDateFormatPipe } from 'src/app/shared/pipes/local-date-format/local-date-format.pipe';
import { BatchInvitesStore } from './batch-invites.store';
import { BaseAPI } from '../base-api';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class BatchInvitesService extends BaseAPI{
  batchInvitesStore = inject(BatchInvitesStore);
  constructor(
    protected http: HttpClient,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {
    super(http);
  }

  getBatchInvitesReport(requestParams: any) {
    this.batchInvitesStore.setLoading(true);
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminBatch/batchinvite-grid/${entityGuid}`, {
      params: requestParams,
    }).pipe(
      tap((entities: any) => {
        const { rows, totalNumberOfItems, showZeroOverlay } = entities;
        const newMetrics = rows?.map((row: any) => {
          const { sendDate, processedDate, ...remaining } = row;
          const splitDateSent = sendDate.split('T')[0];
          const splitDateProcessed = processedDate?.split('T')[0];
          return {
            dateSent: sendDate ? this.localDateFormatPipe.transform(splitDateSent) : '',
            processedDate: processedDate ? this.localDateFormatPipe.transform(splitDateProcessed) : '',
            ...remaining,
          };
        });
        const newEntity = {
            rows: newMetrics,
            totalNumberOfItems,
            showZeroOverlay,
        };
        
        this.batchInvitesStore.setAll(newEntity);
        this.batchInvitesStore.setLoading(false);
      })
    ).subscribe();
  }

  // When status is 'Completed' allow user to download the batch invite report via Email
  exportBatchInvitesReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminBatch/batchinvite-grid/export/${entityGuid}`, {
      params: requestParams,
    });
  }

  // Allow user to cancel a single batch invite
   cancelBatchInvite(batchId: number) {
    return this.http.post(`entityAdminBatch/batch-invite-cancel/${batchId}`, {});
  }

  emailbatchFile(batchId: any) {
    return this.http.get(`entityAdminBatch/batch-export/${batchId}`, {});
  }
}
