import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [
    NgStyle
  ]
})
export class ProgressBarComponent {
  @Input() currentValue!: number;
  @Input() totalValue!: number;
  @Input() color!: string;

  constructor() {} 


  calculateProgressBar(currentValue: number, totalValue: number): string {
    if(totalValue ===0){
      return `calc(0%)`
    }
    return `calc(${(currentValue / totalValue) * 100}%)`;
  }

  calculateProgressBarColor(color: string): string {
    if(!color) {
      color =  'MRTGreen';
    }
    return `var(--${color})`;
  }
}
