import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/api/services/subscription/subscription.service';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NgIf } from '@angular/common';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { MatTableDataSource } from '@angular/material/table';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { SubscriptionViewModel } from 'src/app/api/services/subscription/subscription.model';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SpinnerComponent,
    MRTGridTemplateComponent
  ],
})
export class BillingComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public subscription: SubscriptionViewModel | undefined;
  public hasSubscription = false;
  disableManageButton = true;
  public isEnterprise = true;
  public upgradeRequired = false;
  @Input() hasActiveSubscription = true

  defaultSortColumn: string = 'firstName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
    ],
    openFilters: false,
    columns: [
      { header: 'Date Purchased', controlName: 'datePurchased', displayPipe: 'date' },
      { header: 'Order Number', controlName: 'orderNumber' },
      { header: 'Product Name', controlName: 'productName' },
      { header: 'Quantity', controlName: 'quantity' },
      { header: 'Price', controlName: 'price' }
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    hidepagination: true,
    displaySearch: false,
  };

  
  constructor(
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.whiteBackground();
    this.spinnerService.show();
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    this.subscriptionService
      .getSubscription(entityGuid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscription = res;
        this.isEnterprise = !!this.subscription?.accountBillingOverview?.isEnterprise;
        this.disableManageButton = false;

      if (this.subscription != undefined && this.subscription.status != null) {
        this.hasSubscription = true;
      } else {
        this.hasSubscription = false;
      }
      this.spinnerService.hide();
    });


  this.subscriptionService
  .getPayAsYouGoHistory(entityGuid)
  .subscribe((data: any) => {
    this.gridOptions.data.data = data;
  })


  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  manageSubscription() {
    this.disableManageButton = true;
    this.subscriptionService
      .postManageSession()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        window.location.href = res;
      });
  }

  upgradeClicked() {
    if (this.hasSubscription) {
      this.subscriptionService
        .postManageSession()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          window.location.href = res;
        });
    } else {
      this.modalService.openPricingModal(this.hasSubscription);
    }
  }

  openPricingModal() {
    this.modalService.openPricingModal(this.hasSubscription);
  }

  openPayAsYouGoTab() {
    this.modalService.openPayAsYouGoModal(this.hasSubscription);
  }
}
