import { computed, effect, inject, Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permissions } from '../../../../routes/authentication/components/services/permissions.service';
import { SwitchEntityProfileStore } from 'src/app/api/services/switch-entity/switch-entity-profile.store';
import { AuthenticationStore } from 'src/app/api/services/authentication/authentication.store';
import { toObservable } from '@angular/core/rxjs-interop';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  requiredPermission?: Permissions;
  child?: SubChildren[];
  special?: string;
}

export interface Menu {
  state: string | null;
  name: string;
  tourSelector?: string;
  type: string;
  icon: string;
  expandOnLoad?: boolean;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  requiredPermission?: Permissions;
  onlyRole?: string;
}

export const learnerMenuItems: Menu[] = [
  {
    state: 'my-training',
    tourSelector: 'my-training',
    name: 'My Training',
    type: '',
    icon: 'collections_bookmark',
  },
  {
    tourSelector: 'my-certificates',
    state: 'my-certificates',
    name: 'My Certificates',
    type: '',
    icon: 'verified',
  },
  {
    tourSelector: 'my-history',
    state: 'my-history',
    name: 'My History',
    type: '',
    icon: 'restore',
  },
  {
    tourSelector: 'organization',
    state: 'org-details',
    name: 'Organization',
    type: '',
    icon: 'corporate_fare',
  },
];

export const orgAdminMenuItems: Menu[] = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: '',
    icon: 'dashboard',
  },
  { 
    state: 'groups', 
    name: 'Groups',
    type: '',
    icon: 'group',
     requiredPermission: 'orgAdmin:list'
  },
  { 
    state: 'learners',
    name: 'Learners',
    type: '',
    icon: 'person',
    requiredPermission: 'orgAdmin:list'
  },
  { 
    state: 'trainings',
    name: 'Trainings',
    type: '',
    icon: 'save',
    requiredPermission: 'orgAdmin:list'
  },
  {
    state: 'premium',
    name: 'Premium Features',
    type: 'sub',
    icon: 'home_repair_service',
    children: [
      { state: 'assign', name: 'Assign Training', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'import-learners', name: 'Import Learners', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'join-org', name: 'Join Org Web Page', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    icon: 'assignment_turned_in',
    children: [
      { state: 'learner-reports', name: 'Learner Reports', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'training-reports', name: 'Training Reports', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    state: 'organization',
    name: 'Organization',
    type: 'sub',
    icon: 'corporate_fare',
    children: [
      { state: 'profile', name: 'Profile', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'account-billing', name: 'Account & Billing', type: 'link', requiredPermission: 'orgAdmin:list' },
      { state: 'resources', name: 'Resources', type: 'link', requiredPermission: 'orgAdmin:list' },
    ],
  },
  {
    state: null,
    name: 'My Training',
    type: 'sub',
    icon: 'local_library',
    children: [
      { state: 'my-training', name: 'My Trainings', type: 'link' },
      { state: 'my-certificates', name: 'My Certificates', type: 'link' },
      { state: 'my-history', name: 'My History', type: 'link' },
    ],
  },
];

export const coopAdminMenuItems: Menu[] = [
  {
    state: 'coop/dashboard',
    name: 'Dashboard',
    type: '',
    icon: 'dashboard',
  },
  {
    state: 'coop/profile',
    name: 'Organization Profile',
    type: '',
    icon: 'corporate_fare',
  },
  {
    state: 'coop/trainings',
    name: 'Trainings',
    type: '',
    icon: 'collections_bookmark',
  },
  {
    state: 'coop/team',
    name: 'Team',
    type: '',
    icon: 'groups',
  },
  {
    state: 'coop/certificates',
    name: 'Certificates',
    type: '',
    icon: 'assignment_turned_in',
  },
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
  readonly switchProfileEntityStore = inject(SwitchEntityProfileStore);
  readonly authenticationStore = inject(AuthenticationStore);

  readonly menuItemsSignal = computed(() => {
    const activeProfile = this.switchProfileEntityStore.activeProfileSignal();
    const user = this.authenticationStore.userSignal();

    if (!activeProfile || !user || !user.orgAndRoles) {
      return [];
    }

    let entityRoleNames: any[] = [];
    if (activeProfile) {
      entityRoleNames = activeProfile.roles;
      if (entityRoleNames.length > 0) {
        if (entityRoleNames.includes('EntityAdmin')) {
          if (activeProfile.isOffsite) {
            var menuItemsWithOffsite = orgAdminMenuItems.map((item) => {
              if (item.state === 'reports' && !item.children?.some(c => c.special === 'offsite')) {
                item.children?.push({
                  state: 'offsite-certs',
                  name: 'Offsite LMS Certs',
                  type: 'link',
                  requiredPermission: 'orgAdmin:list',
                  special: 'offsite',
                });
              }
              return item;
            });
            return menuItemsWithOffsite;
          }

          return orgAdminMenuItems;
        } else if (entityRoleNames.includes('CoopAdmin')) {
          return coopAdminMenuItems;
        } else {
          // remove the org details menu item if the user is in an Individual org
          if (activeProfile.entityType !== 'Organization') {
            const learnerMenuItemsCopy = learnerMenuItems.filter((item) => item.state !== 'org-details');
            return learnerMenuItemsCopy;
            }
          }

          return learnerMenuItems;
        }
      }
    }
  );


}
