import { Component, OnInit, EventEmitter, Output, OnDestroy, inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { FormSelectComponent } from '../../../../../forms/components/form-select/form-select.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LearnersStore } from 'src/app/api/services/learners/learners.store';
import { LearnersService } from 'src/app/api/services/learners/learners.services';
import { AuthenticationStore } from 'src/app/api/services/authentication/authentication.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthenticationService } from 'src/app/api/services/authentication/authentication.service';

@Component({
    selector: 'app-add-users',
    templateUrl: './add-users.component.html',
    styleUrls: ['./add-users.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgFor,
        MatFormFieldModule,
        MatInputModule,
        NgClass,
        FormSelectComponent,
        MatIconModule,
    ],
})
export class AddUsersComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  readonly learnersStore = inject(LearnersStore);
  readonly authenticationStore = inject(AuthenticationStore);
  user$ = toObservable(this.authenticationStore.userSignal);

  destroy$: Subject<boolean> = new Subject<boolean>();
  entityGuid!: string;
  public addUsers: UntypedFormGroup = Object.create(null);
  validEmailSpinner: boolean = false;
  disableFlag: boolean = false;
  learnerDisabled: boolean = false;
  numberOfStartingEntries = 5;
  maxNumberOfEntries = 10;
  @Output() dataEvent = new EventEmitter<{ currentAddLearnerStep: number; title: string }>();
  errorResponses: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private learnersService: LearnersService,
    private authService: AuthenticationService,
    public lookupsService: LookupsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get learnersAccessControl(): UntypedFormArray {
    return this.addUsers.get('learners') as UntypedFormArray;
  }

  public addElement(options: { type: 'learners' }) {
    const { type } = options;
    if (type === 'learners') {
      this.learnersAccessControl.push(this.addRow());
    }
  }

  public addRow() {
    if (this.addUsers.value['learners'].length >= this.maxNumberOfEntries - 1) {
      this.learnerDisabled = true;
    }
    const learnerFormGroup = new UntypedFormGroup({
      email: this.fb.control(null, Validators.compose([Validators.email])),
      groupIds: this.fb.control(null),
    });
    if (this.addUsers.value['learners'].length > 0) {
      learnerFormGroup.get('email')?.valueChanges.subscribe((value) => {
        if (value) {
          learnerFormGroup.get('groupIds')?.setValidators([Validators.required]);
        } else {
          learnerFormGroup.get('groupIds')?.setValidators([]);
        }
        learnerFormGroup.get('groupIds')?.updateValueAndValidity();
      });
    } else {
      learnerFormGroup.get('email')?.setValidators([Validators.required]);
      learnerFormGroup.get('groupIds')?.setValidators([Validators.required]);
    }
    return learnerFormGroup;
  }

  deleteItem(rowId) {
    if (this.addUsers.value['learners'].length <= this.maxNumberOfEntries) {
      this.learnerDisabled = false;
    }
    this.learnersAccessControl.removeAt(rowId);
  }

  ngOnInit(): void {
    if (this.lookupsStore.groupsSignal().length == 0) {
      this.lookupsService.getGroups();
    }
    this.addUsers = this.fb.group({
      learners: this.fb.array([]),
    });
    for (let i = 0; i < this.numberOfStartingEntries; i++) {
      this.learnersAccessControl.push(this.addRow());
    }
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      const { orgAndRoles } = user;
      orgAndRoles.forEach((entity: any) => {
        this.entityGuid = entity.entityGuid;
      });
    });
    this.addUsers.valueChanges.subscribe(() => {
      if (this.addUsers.valid) {
        this.disableFlag = false;
      }
    });
  }

  onSubmitUsers() {
    const entityGuid = window.localStorage.getItem('entityGuid');
    if (!entityGuid) {
      return;
    }
    this.disableFlag = true;
    if (!this.addUsers.valid) {
      this.addUsers.markAllAsTouched();
      return;
    }
    const learners = this.addUsers.value['learners'].filter((learner) => {
      if (learner.email && learner.groupIds) {
        return learner;
      }
    });
    this.learnersService
      .addOrgUsers(learners)
      .pipe(
        catchError((error) => throwError(error)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (responses: any) => {
          this.errorResponses = responses.failedEmails;
          if (this.errorResponses.length === 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "User Invites sent" }})
            this.emitData(3);
            this.disableFlag = false;
          }

          this.learnersAccessControl.controls.forEach((control) => {
            control.setValue({
              email: null,
              groupIds: null,
            });
            control.markAsUntouched();
          });
          this.disableFlag = false;
        },
        (error) => {
          this.disableFlag = false;
        }
      );
  }

  checkEmail(email: any, control: any) {
    if (control?.errors === null) {
      this.validEmailSpinner = false;
      const entityGuid = window.localStorage.getItem('entityGuid');
      if (!entityGuid) {
        return;
      }
      this.authService
        .entityUserEmailExist(email.target.value, entityGuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            if (data) {
              control.markAsTouched();
              control.setErrors({
                message: `Username '${email.target.value}' is already part of the organization.`,
              });
            }
            this.validEmailSpinner = false;
          },
          (error) => {
            this.validEmailSpinner = false;
          }
        );
    }
  }
  backStep() {
    this.emitData(1);
  }
  emitData(step: number) {
    const data = {
      currentAddLearnerStep: step,
      title: 'Add Learners',
    };
    this.dataEvent.emit(data);
  }
}
