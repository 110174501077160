<div class="login flex items-center justify-center">
  <div class="bg-white site-container-760 py-10 flex justify-center  border-10">
    <div class="w-full lg:w-3/4">
      <div class="flex flex-col justify-center">
        <h1 class="text-primary  font-bold  w-full text-center leading-none mx-auto mb-3">
          <span class="block">Reset<br>Password</span>
        </h1>
        <p class="text-center font-14 mb-10">Enter your new password.</p>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field class="w-full" [ngClass]="{ 'mb-20 sm:mb-9': form.controls['newPassword'].errors?.message }">
            <mat-label>Password</mat-label>
            <input [type]="hide ? 'password' : 'text'" matInput formControlName="newPassword" />
            <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
              hide ? 'visibility' : 'visibility_off'
            }}</mat-icon>

            <mat-error
              *ngIf="form.controls['newPassword'].hasError('required') && form.controls['newPassword'].touched"
              class="text-danger support-text"
              >You must include password.
            </mat-error>
          </mat-form-field>
        </div>
        <div [ngClass]="{ 'mb-4 -mt-12 ': form.controls['newPassword'].errors?.message }">
          <mat-error class="text-danger support-text" *ngIf="form.controls['newPassword'].errors?.message">{{
            form.controls['newPassword'].getError('message')
          }}</mat-error>
        </div>
        <div>
          <mat-form-field class="w-full">
            <mat-label>Confirm Password</mat-label>
            <input [type]="chide ? 'password' : 'text'" matInput formControlName="confirmPassword"  />
            <mat-icon matSuffix class="cursor-default eye-hidden" (click)="chide = !chide">{{
              chide ? 'visibility' : 'visibility_off'
            }}</mat-icon>

            <mat-error
              *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched"
              class="text-danger support-text"
              >You must include confirm password.
            </mat-error>
            <mat-error *ngIf="form.controls['confirmPassword']?.errors?.equalTo" class="text-danger font-14"
              >Passwords do not match.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-center">
          <button type="submit" class="btn-primary large" [disabled]="disableFlag">Reset</button>
        </div>
      </form>
    </div>
  </div>
</div>
