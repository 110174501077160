import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BaseAPI } from '../base-api';
import { LocalDateFormatPipe } from 'src/app/shared/pipes/local-date-format/local-date-format.pipe';
import { LearnerMetricsStore } from './learner-metrics.store';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LearnerMetricsService extends BaseAPI{
  learnerMetricsStore = inject(LearnerMetricsStore);
  constructor(
    http: HttpClient,
    private localDateFormatPipe: LocalDateFormatPipe
  ) {
    super(http);
  }

  getLearnerMetricsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    this.learnerMetricsStore.setLoading(true);
    return this.http.get(`entityAdminReports/learner-metrics/${entityGuid}`, {
      params: requestParams,
    }).pipe(
      tap((entities: any) => {
        const { rows, totalNumberOfItems } = entities;
        const newRows = rows?.map((row: any) => {
          const { dateAdded, dateCompleted, ...remaining } = row;
          const splitDateAdded = dateAdded.split('T')[0];
          const splitDateCompleted = dateCompleted?.split('T')[0];
          return {
            dateAdded: this.localDateFormatPipe.transform(splitDateAdded),
            dateCompleted: dateCompleted ? this.localDateFormatPipe.transform(splitDateCompleted) : '',
            ...remaining,
          };
        });
        const newEntities = {
          rows: newRows,
          totalNumberOfItems,
        };
        this.learnerMetricsStore.setAll(newEntities);
        this.learnerMetricsStore.setLoading(false);
      })
    );
  }

  exportLearningMetricsReport(requestParams: any) {
    const entityGuid: any = localStorage.getItem('entityGuid');
    return this.http.get(`entityAdminReports/learner-metrics/export/${entityGuid}`, {
      params: requestParams,
    });
  }
}