import { Component, effect, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, of, throwError } from 'rxjs';
import { catchError, take, tap, map, switchMap, takeUntil } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DialogData, UserResetPasswordComponent } from '../../../../../shared/components/modals/user-reset-password/user-reset-password.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserGroupsService } from 'src/app/api/services/userGroups/user-groups.service';
import { SubscriptionService } from 'src/app/api/services/subscription/subscription.service';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { FormSelectComponent } from '../../../../../forms/components/form-select/form-select.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BlockCopyPasteDirective } from '../../../../../shared/directives/block-copy-paste/block-copy-paste.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgIf, NgFor, NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { AssignTrainingComponent } from 'src/app/shared/components/modals/assign-training/assign-training.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LearnersService } from 'src/app/api/services/learners/learners.services';
import { LearnersStore } from 'src/app/api/services/learners/learners.store';
import { LearnerMetricsStore } from 'src/app/api/services/learner-metrics/learner-metrics.store';
import { SwitchEntityProfileStore } from 'src/app/api/services/switch-entity/switch-entity-profile.store';
import { CertificatesService } from 'src/app/api/services/certificate/certificates.service';

@Component({
    selector: 'app-edit-users',
    templateUrl: './edit-users.component.html',
    styleUrls: ['./edit-users.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        MatTabsModule,
        NgTemplateOutlet,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        FormSelectComponent,
        MatIconModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTableModule,
        AsyncPipe,
        MRTGridTemplateComponent,
    ],
})
export class EditUsersComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  readonly learnersStore = inject(LearnersStore);
  readonly learnerMetricsStore = inject(LearnerMetricsStore);
  readonly switchEntityProfileStore = inject(SwitchEntityProfileStore);

  destroy$: Subject<boolean> = new Subject<boolean>();
  userId!: string;
  smallScreen!: boolean;
  disableFlag: boolean = false;
  hasActivityInSubscriptionYear: boolean = true;
  userName!: string;
  intMax: number = 2147483647;
  gridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Training', controlName: 'trainingType' },
      { header: 'Course', controlName: 'training' },
      { header: 'Added', controlName: 'dateAdded', displayPipe: 'date' },
      { header: 'End', controlName: 'dateCompleted', displayPipe: 'date' },
      { header: 'Total Time', controlName: 'totalTime' },
      { header: 'Certificate', controlName: 'certificateName',
        actionColumnitems: [
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificateName' },
          }
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    addButtonName: 'Assign Training',
    disableflag: false,
    isLoading: false,
    fetchData: false,
    hidepagination: true,
    displaySearch: false,
    hideSort: true,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private learnersService: LearnersService,
    private snackBar: MatSnackBar,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private userGroupsService: UserGroupsService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private certificatesService: CertificatesService,
    public lookupsService: LookupsService,
  ) {
    effect(() => {
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.learnersStore.learnerSignal()?.courseHistory.rows ?? [];
      this.gridOptions.totalNumberOfItems = this.learnersStore.learnerSignal()?.courseHistory.totalNumberOfItems ?? 0;
    });
  }

  public addUsers: UntypedFormGroup = Object.create(null);

  openDialog(options: {
    mode: 'add' | 'edit' | 'delete';
    componentType: 'reset-password';
    item?: any;
    deleteId?: any;
    itemName?: string;
  }) {
    const { mode, componentType } = options;
    const data: DialogData = { mode, email: this.addUsers?.controls?.email?.value };
    if (componentType === 'reset-password') {
      this.modalService.open(UserResetPasswordComponent, {
        modalType: ModalType.Medium,
        data,
      });
    }
  }

  ngOnInit(): void {
    this.lookupsService.getEntityUserStatus();
    this.lookupsService.getRoles();
    this.lookupsService.getGroups();

    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.smallScreen = !result.matches;
      });
    this.addUsers = this.fb.group({
      firstName: this.fb.control({ value: null, disabled: true }, Validators.required),
      lastName: this.fb.control({ value: null, disabled: true }, Validators.required),
      email: this.fb.control(
        { value: null, disabled: true },
        Validators.compose([Validators.required, Validators.email])
      ),
      status: this.fb.control(null),
      entityGroupIds: this.fb.control(null),
      roles: this.fb.control(null),
    });

    this.activatedRoute.queryParams
      .pipe(
        map((params) => {
          this.userId = params['userId'];
          this.fetchAll();
          return params['userId'];
        }),
        switchMap((params) => {
          return this.learnersService.getUser(params);
        }),
        tap((data: any) => {
          this.loadDetails(data);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.hasActivityInSubscriptionYear = data.hasActivityInSubscriptionYear;
        this.userName = data.firstName + ' ' + data.lastName;
      });
  }

  loadDetails(data: any) {
    let roleResult: any[] = [];
    let { roles, statusId, coursesAvailableForAssignment, ...remaining } = data;

    roles.forEach((role: any) => {
      roleResult.push(role.id);
    });
    const formValues = {
      roles: roleResult,
      status: statusId,
      ...remaining,
    };
    this.addUsers.patchValue({ ...formValues });
  }

  update() {
    if (!this.addUsers.valid) {
      this.addUsers.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const entityGuid: any = window.localStorage.getItem('entityGuid');

    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.addUsers.get(error.field);
          if (control) {
            this.addUsers.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors?.error['roleIds']?.length > 0) {
        const control = this.addUsers.get('roles');
        if (control) {
          this.addUsers.markAllAsTouched();
          control.setErrors({
            message: 'Entity Admin self Inactivation is not allowed',
          });
        }
      } else {
       if(validationErrors.error?.activityInSubscriptionYear.length > 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Cannot be archived at this time. Training activity in current subscription period." }})
       } else {
         this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
       }
      }

      return of(null);
    };
    const { status, entityGroupIds, roles, ...remaining } = this.addUsers.getRawValue();
    const formValues = {
      status: status,
      entityUserId: this.userId,
      groupIds: entityGroupIds,
      entityGuid: entityGuid,
      roleIds: roles,
      ...remaining,
    };

    this.learnersService
      .update(formValues)
      .pipe(
        catchError((error) => throwError(error)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: "User Updated" }})
          this.userGroupsService.getUserGroups(entityGuid, { pageNumber: 1, pageSize: 20 })

          this.disableFlag = false;
          this.subscriptionService.getSubscriptionUsage(entityGuid)?.pipe(takeUntil(this.destroy$)).subscribe();
        },
        (error) => {
          validationErrorHandler(error);
          this.disableFlag = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  fetchAll() {
    var gridDatasource: any[] = [];
    if (this.userId === undefined || this.userId === null) {
      return;
    }
    this.learnersService.getUser(this.userId)
  }

  downloadCert(element: any) {
    this.certificatesService.downloadCert(element?.certificateId);
  }

  assignTraining() {
    const data = { 
      entityUserId: this.userId,
      email: this.addUsers?.controls?.email?.value,
      name: this.addUsers?.controls?.firstName?.value + ' ' + this.addUsers?.controls?.lastName?.value
    };
      this.modalService.open(AssignTrainingComponent, {
        modalType: ModalType.FullScreen,
        data,
      },
      (result) => {
        if (result) {
          this.fetchAll();
        }
      });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      //this.fetchAll();
    } else if (event.action === 'formChanges') {
      //this.fetchAll();
    } else if (event.action === 'clearFilters') {
      //this.fetchAll();
    } else if (event.action === 'addButton') {
      this.assignTraining();
    }
    //  'download' is specific to this component 
    else if (event.action === 'download') {
      if (event.data.certificateId == 0) {
        this.modalService.openOrgCertNotIssued();
      } else {
        this.downloadCert(event.data);
      }
    }
  }
}
