import { inject, Injectable } from '@angular/core';
import { paths } from '../../interfaces/swagger-types';
import { ResourcesStore } from './resources.store';
import { HttpClient } from '@angular/common/http';


export type AddResources =
  paths['/api/EntityAdminResource/upsert-resource/{entityGuid}']['post']['requestBody']['content']['multipart/form-data'];
@Injectable({
  providedIn: 'root',
})
export class ResourcesService{
  constructor(private http: HttpClient) {}

  resourcesStore = inject(ResourcesStore);

  getResources(entityGuid: string, requestedBody: any) {
    this.resourcesStore.setResourcesLoading(true);

    return this.http.get(`resource/${entityGuid}`, {
      params: {
        SearchTerm: requestedBody.SearchTerm,
        Types: requestedBody.Types,
        Statuses: requestedBody.Statuses,
      },
    }) 
    .pipe()
    .subscribe((res: any) => {
      this.resourcesStore.setResources(res);
      this.resourcesStore.setResourcesLoading(false);
    });
  }

  addResource(body: FormData) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    return this.http.post(`entityAdminResource/upsert-resource/${entityGuid}`, body);
  }

  fileDownload(resourceId: any, fileName: string, fileType: string) {
    const entityGuid: any = window.localStorage.getItem('entityGuid');
    let url = `resource/download/${entityGuid}/${resourceId}`;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((res) => {
      const blob = new Blob([res]);
      this.downloadFile(blob, fileName, fileType);
    });
  }

  downloadFile(blob: Blob, fileName: string, fileType: string) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}


