<div class="flex flex-col gap-2 md:flex-row justify-between md:items-center m-4 p-3 lg:p-10">
  <div>
    <div>
      <h1 class="learner text-primary font-bold">{{ title }}</h1>
      <p class="pt-4">{{ message }} <span *ngIf="link"><a [href]="link" target="_blank" class="primary-link underline">{{linkText}}</a></span></p>
    </div>
  <div *ngIf="buttonText" class="py-4 flex items-center text-base">
    <button
      class="btn-primary medium cursor-pointer items-center text-center whitespace-nowrap"
      *ngIf="buttonText && !disableButton"
      (click)="onButtonClick()"
      [disabled]="disableButton"
    >
      {{ buttonText }}
    </button>
  </div>
  </div>
  <div class="w-auto m-4">
    <img src="/assets/images/setup-wizard/boxster-welcome.svg" alt="">
  </div>
</div>
