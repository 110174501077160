import { Component, OnInit, OnDestroy, Input, inject } from '@angular/core';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { FormSelectComponent } from 'src/app/forms/components/form-select/form-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { OrganizationService } from 'src/app/api/services/organizations/organization.service';
import { ModalService, ModalType } from 'src/app/shared/services/modal.service';
import { SelectAssignTrainingComponent } from 'src/app/shared/components/modals/select-assign-training/select-assign-training.component';
import { SelectLearnerEmailsComponent } from 'src/app/shared/components/modals/select-learner-emails/select-learner-emails.component';
import { CommonModalComponent, CommonModalDialogData } from 'src/app/shared/components/modals/common-modal/common-modal.component';
import { RouterModule, Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { BoxyMessageComponent, BoxyMessageOptions } from 'src/app/shared/components/boxy-message/boxy-message.component';
import { OrgAdminBannerComponent } from 'src/app/shared/components/layouts/banner/org-admin-banner/org-admin-banner.component';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { SubscriptionStore } from 'src/app/api/services/subscription/subscription.store';
import { OrganizationStore } from 'src/app/api/services/organizations/organization.store';

@Component({
  selector: 'app-batch-assign-training',
  templateUrl: './batch-assign-training.component.html',
  styleUrls: ['./batch-assign-training.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    FormSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgClass,
    MatIconModule,
    SnackbarComponent,
    DatePipe,
    MatDatepickerModule,
    MatRadioModule,
    RouterModule,
    BoxyMessageComponent,
    OrgAdminBannerComponent,
],
})
export class BatchAssignTrainingComponent implements OnInit, OnDestroy {
  readonly organizationStore = inject(OrganizationStore);
  readonly subscriptionStore = inject(SubscriptionStore);
  readonly lookupsStore = inject(LookupsStore);
  @Input() boxyMessage: BoxyMessageOptions = {
    title: '',
    message: '',
    linkText: '',
    link: '',
    buttonText: '',
    disableButton: false,
  }
  hasSubscription = false;
  stepper = 1;
  courseIds: any[] = [];
  courseDisplays: any[] = [];
  assignTrainingForm: UntypedFormGroup = Object.create(null);
  users$: any[] = [
    { label: 'All', value: 'isAllLearners' },  // send bool
    { label: 'Group', value: 'group' }, // choose groups & send groupId
    { label: 'Select Users', value: 'selectUsers' }, // select & send learnerEmails   
  ];
  currentUsersValue = "";
  learnerEmails: any[] = [];
  learnerNames: any[] = [];
  isAllLearners = false;
  groupNames: any[] = [];
  

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private organizationService: OrganizationService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    public lookupsService: LookupsService,
  ) { }

  futureDatesFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return d > today;
  }

  dateValidator = (control: FormControl) => {
    const date = control.value;
    if (!date) return null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date > today ? null : { invalidDate: true };
  }

  ngOnInit(): void {
    if (this.lookupsStore.groupsSignal().length == 0) {
      this.lookupsService.getGroups();
    }

    this.boxyMessage = {
      ... this.boxyMessage,
      title: 'Assign Training',
      message: "This premium feature allows you to automatically assign a training session to groups and selected learners in your organization. Use this to assign a new training or a new session to renew an existing training. ",
      linkText: 'Find out more.',
      link: 'https://mandatedreportertraining.zendesk.com/hc/en-us/articles/26926118661531-Auto-Assign-Training',
      buttonText: 'View Pricing',
    }
    this.assignTrainingForm = this.fb.group({
      users: ['', Validators.required],
      groupIds: [[]],
      dueDate: ['', [this.dateValidator]],
      email: [true],
    });

    // check if user has active subscription
    const subscription = this.subscriptionStore.subscriptionSignal();
    if (subscription) {
      if (!subscription.hasActiveSubscription) {
        this.hasSubscription = false;
        this.disableFormFields();
      } else {
        this.hasSubscription = true;
      }
    }


    this.assignTrainingForm.valueChanges.subscribe((value) => {
      // select users
      if (value.users !== 'selectUsers' && this.currentUsersValue === 'selectUsers') {
        this.learnerEmails = []; 
        this.learnerNames = [];
      }
      // all learners
      if ( value.users === 'isAllLearners') {
        this.isAllLearners = true;
      } else if (this.currentUsersValue === 'isAllLearners') {
        this.isAllLearners = false;
      }
      // group
      if (value.users === 'group') {
        this.assignTrainingForm.get('groupIds')?.setValidators(Validators.required);
        this.groupNames = this.lookupsStore.groupsSignal().filter((group) => value.groupIds.includes(group.value)).map((group) => group.name);
      } else if (this.currentUsersValue === 'group') {
        this.currentUsersValue = value.users;
        this.assignTrainingForm.get('groupIds')?.setValue([]);
        this.assignTrainingForm.get('groupIds')?.clearValidators();
        this.assignTrainingForm.get('groupIds')?.markAsUntouched();
        this.assignTrainingForm.get('groupIds')?.updateValueAndValidity();
      }
      this.currentUsersValue = value.users;
    });
  }

  ngOnDestroy(): void {
    this.organizationStore.resetEditCourse();
  }

  calculateDate(val: any) {
    if (val) {
      return new Date(val).toISOString();
    }
  }

  selectUsers() {
    this.modalService.open(SelectLearnerEmailsComponent, {
      modalType: ModalType.FullScreen,
    }, (learners: any) => {
      if (learners) {
        this.learnerEmails = learners.map((learner: any) => learner.value);
        this.learnerNames = learners.map((learner: any) => learner.name);
      }
    });
  }

  selectTraining() {
    this.modalService.open(SelectAssignTrainingComponent, {
      modalType: ModalType.FullScreen,
    }, (courses: any) => {
      if (courses) {
        this.courseIds = courses.map((course: any) => course.id);
        this.courseDisplays = courses.map((course: any) => {
          return `${course.trainingHeader}: ${course.name}` + (course.state !== 'NATL' ? ` | ${course.state}` : "");
        });
      }
    });
  }

  next() {
    // validate form
    if (!this.assignTrainingForm.valid) {
      this.assignTrainingForm.markAllAsTouched();
      return;
    }
    if (this.courseIds.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          message: 'Please select a training',
          toastType: ToastType.Error,
        },
      });
      return;
    }
    if (this.currentUsersValue === 'group' && this.assignTrainingForm.value.groupIds.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          message: 'Please select a group',
          toastType: ToastType.Error,
        },
      });
      return;
    } else if (this.currentUsersValue === 'selectUsers' && this.learnerEmails.length === 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          message: 'Please select at least one user',
          toastType: ToastType.Error,
        },
      });
      return;
    }
    this.stepper++;
  }

  backStep() {
    this.stepper--;
  }

  disableFormFields() {
    this.assignTrainingForm.disable();
  }

  onBoxyButtonClick() {
    this.modalService.openPricingModal(this.hasSubscription);
  }

  assignTraining() {
    var message1 = `You are assigning the training${this.courseDisplays.length > 1 ? 's' : ''} ${this.courseDisplays.join(', ')} to `;
    if (this.isAllLearners) {
      message1 += 'all active users';
    } else if (this.currentUsersValue === 'group') {
      message1 += `${this.groupNames.length} ${this.groupNames.length > 1 ? 'groups' : 'group'}`;
    } else {
      message1 += `${this.learnerNames.length} ${this.learnerNames.length > 1 ? 'users' : 'user'}`;
    }
    this.modalService.open(CommonModalComponent, {
      modalType: ModalType.Medium,
      data: {
        title: 'Are You Sure?',
        message1: message1,
        button1Text: 'Assign Training',
        button1Clicked: () => {
          const formValue = this.assignTrainingForm.value;
          const data = {
            isAllLearners: this.isAllLearners,
            courseIds: this.courseIds,
            groupIds: formValue.groupIds,
            learnerEmails: this.learnerEmails,
            dueDate: formValue.dueDate ? this.calculateDate(formValue.dueDate) : '',
            email: formValue.email,
          };

          this.spinnerService.show();
          this.modalService.close();
          this.organizationService.batchAssignTraining(data).pipe(
            catchError((error) => {
              this.spinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: {
                  message: error.error.message,
                  toastType: ToastType.Error,
                },
              });
              this.stepper--;
              return throwError(error);
            }),
          ).subscribe((response) => {
            this.stepper++;
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: {
                message: 'Training will be assigned',
                toastType: ToastType.Success,
              },
            });
          });
        },
      } as CommonModalDialogData,
    });

  }
}
