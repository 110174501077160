<div *ngIf="loading" class="preloader fixed">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>
<div *ngIf="!loading">
  
  <ng-container *ngIf="!is2FaRequired; else show2fa">
    <div *ngIf="inviteValid" class="noti-container">
      <div class="noti">
        <p *ngIf="!joinRequestGuid">Log in to respond to your invitation.</p>
        <p *ngIf="joinRequestGuid">Log in to join {{ inviteOrgName }}</p>
      </div>
    </div>
    <div class="items-center flex flex-col justify-center">
      <div *ngIf="email" class="noti-container mb-0 mt-4">
        <div class="noti">
          <p><strong>{{ email }}</strong> is already a user. Log in with your existing account to join this
            organization.</p>
        </div>
      </div>


      <div class="login w-full px-4">

        <div class="lg:gap-12 flex site-container-1340 flex-col lg:flex-row">
          <div class="w-full lg:w-1/2  mt-8 lg:mt-0 ">
            <div class="w-full h-auto">
              <div class="flex justify-between flex-col md:flex-row w-full pt-8 login-dynamic-width">
                <div class="flex-1">
                  <h1 class="text-primary font-bold mb-5 text-center lg:text-left">Log In</h1>
                  <form [formGroup]="form">
                    <div>
                      <mat-form-field class="w-full">
                        <mat-label>Email Address</mat-label>
                        <input matInput type="email" (blur)="checkEmail($event, form.controls['userName'])"
                          formControlName="userName" />
                        <mat-error *ngIf="form.controls['userName'].errors?.email && form.controls['userName'].touched">You
                          must include a valid email.
                        </mat-error>
                      </mat-form-field>
                      <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['userName'].errors?.invite }">
                        <mat-error class="text-danger support-text" *ngIf="form.controls['userName'].errors?.invite">
                          {{ form.controls['userName'].getError('invite').message }}
                          <br />
                          <span class="underline cursor-pointer"
                            (click)="resendInvite(form.controls['userName'].getError('invite').email)">Resend Invite
                            Email</span>
                        </mat-error>
                      </div>
                    </div>
                    <div>
                      <mat-form-field class="w-full pb-0">
                        <mat-label>Password</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                        <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
                          hide ? 'visibility' : 'visibility_off'
                          }}</mat-icon>
                        <mat-error>Must be a valid Password</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="-mt-4 relative z-[1]" *ngIf="verifyEmail">
                      <mat-error>
                        You need to confirm your email.
                        <span class="underline cursor-pointer" (click)="resendEmail()">Resend Verification Email</span>
                      </mat-error>
                    </div>
                    <div class="text-center lg:text-left">
                      <button type="submit" (click)="onSubmit()" [disabled]="disableFlag"
                        class="btn-primary large  mb-10 mt-10">
                        Log In
                      </button>
                    </div>
                    <div class="mt-0 sm:mt-md mb-md text-center lg:text-left">
                      <a href="/forgot" class="capitalize primary-link underline">forgot password?</a>
                      <a class=""><br />
                        Don't have an account?
                        <a href="https://mandatedreportertraining.com/start-now" class="primary-link underline">Register</a>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          

          <div class="w-full lg:w-1/2 mt-4 lg:mt-0" *ngIf="advertisement !== null">
            <div class="login-ad">
              <img [src]="advertisement.imageUrl" alt="Login Advertisement">
              <div class="text-center mt-8">
                <h2>{{advertisement.title}}</h2>
                <h4>{{advertisement.description}}</h4>
              </div>

            </div>
          </div>
  
        </div>
      </div>



    </div>
  </ng-container>




  <ng-template #show2fa>
    <div class="login items-center flex flex-col justify-center">
      <div class="site-container-760 bg-white p-8">
        <ng-container *ngIf="downloadApp;">
          <div class="text-center login-container">
            <h1>Two-Factor Authentication</h1>
            <p class="mt-3">Your organization requires additional authentication to keep your account secure.
            </p>
            <h2 class="learner mt-4 strong">Download an Authenticator App</h2>
            <p>On your phone, download and install an Authenticator App.</p>
            <p class="mt-3 flex items-center justify-center">Google Authenticator <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a> <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US&pli=1"
                target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a></p>
            <p class="flex items-center justify-center">Microsoft Authenticator <a
                href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a> <a
                href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US"
                target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a></p>
            <p class="mt-3">Once you've installed the Authenticator App, click Next.</p>
            <div class="my-5">
              <button class="btn-primary large" (click)="showQRCode()">Next</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="setupVerifyAuthCode;">
          <div class="w-screen login-container">
            <div class="h-full text-center">
              <ng-container *ngIf="!is2faSetup">
                <h1>Two-Factor Authentication</h1>
                <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account
                  secure.</p>
                <h2 class="learner mt-10 strong">Scan the QR Code</h2>
                <p class="width25 mx-auto">Open the Authenticator App, follow instructions to add a new account and
                  use the app to scan the QR Code below.</p>
                <div id="qrCode" class="w-[150px] my-4 mx-auto">
                  <img [src]="qrCodeImgSrc">
                </div>
              </ng-container>
              <button (click)="showVerifyAuthCode()" class="btn-primary large">
                Verify
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="verifyAuthCode;">
          <div class="login-container">
            <div class="h-full text-center">
              <h1>Two-Factor Authentication</h1>
              <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account secure.
              </p>
              <h2 class="learner mt-10 strong">Enter Verification Code</h2>
              <p class="width25 mx-auto">Open your Authenticator App and enter the 6-digit code below.</p>
              <form class="flex flex-col items-center mt-8" [formGroup]="form">
                <div class="width25">
                  <mat-form-field class="flex mx-auto">
                    <mat-label>Verification Code</mat-label>
                    <input matInput type="text" class="w-full" formControlName="verificationCode" />
                    <mat-error
                      *ngIf="form.controls['verificationCode'].errors && form.controls['verificationCode'].touched">
                      That code didn't work
                    </mat-error>
                  </mat-form-field>
                  <button type="submit" (click)="onSubmit2fa()" [disabled]="disableFlag" class="btn-primary large mt-8">
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>