<footer
  class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3 pr-8">
  <div class="copywrite">
    <p class="small mb-0"> &copy; {{ date }} <a href="https://mandatedreportertraining.com/" target="_blank"
      class="primary-link">
      Mandated Reporter Training
    </a></p>
  </div>
  <div class="copywrite">
    <ng-container>
      <p class="small lg:flex flex-col lg:flex-row">
        <span class="mx-1 hidden lg:inline-block">|</span>
        <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
          class="primary-link">
          Policies
        </a>
      </p>
    </ng-container>
  </div>
  <p class="small copywrite">
    <span class="mx-1 hidden lg:inline-block">|</span>
    <span class="mr-1 w-full">Powered by <a href="https://simplelearning.com" target="_blank"
        class="primary-link">Simple</a>
    </span>

    <br class="lg:hidden">
    <span class="copywrite" *ngIf="!loggedIn">
      <span class="mx-1 font-normal hidden lg:inline-block">|</span>
       v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }}
    </span>
  </p>
</footer>