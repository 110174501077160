import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserInvitesService{
  constructor(private http: HttpClient) {}
  
  getAll(guid: string | null, paramsRequested: any) {
    return this.http.get(`EntityAdminUserInvite/userinvites-grid/${guid}`, {
      params: paramsRequested,
    });
  }

  resendInvitation(formValues: any) {
    return this.http.post(`EntityAdminUserInvite/resend`, formValues);
  }

  cancelInvite(inviteGuid: any) {
    return this.http.post(`EntityAdminUserInvite/cancel/${inviteGuid}`, null);
  }

}
