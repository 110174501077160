import { Component, inject, OnInit } from '@angular/core';
import { DatePipe, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthenticationStore } from 'src/app/api/services/authentication/authentication.store';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgIf,   DatePipe]
})
export class FooterComponent implements OnInit {
  readonly authenticationStore = inject(AuthenticationStore);
  userLoggedIn$ = toObservable(this.authenticationStore.userIsLoggedInSignal);

  date: number = new Date().getFullYear();
  loggedIn: boolean = false;
  versionNumber!: { number: string; date: string };

  constructor() {}

  ngOnInit(): void {
    this.versionNumber = {
      number: environment.versionNumber.split('|')[0],
      date: environment.versionNumber.split('|')[1],
    };


    this.userLoggedIn$.subscribe((yes) => {
      if (yes) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }
}
