import { Injectable, inject} from '@angular/core';
import { BaseAPI } from '../base-api';
import { UserGroupsStore } from './user-groups.store';
import { UserGroup } from './user-groups.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class UserGroupsService{
    userGroupsStore = inject(UserGroupsStore);
    
    constructor(private http: HttpClient) {}

    upsertGroup(UserGroup: UserGroup) {
      return this.http.post('EntityAdminEntity/add-entity-group', UserGroup);
    }

    updateGroup(groupId: any, UserGroup: UserGroup) {
      const entityGuid: any = window.localStorage.getItem('entityGuid');
      return this.http.put(`EntityAdminEntity/update-entity-group/${entityGuid}/${groupId}`, UserGroup);
    }

    getUserGroups(entityGuid: string, params?: any) {
      this.userGroupsStore.setUserGroupsLoading(true);
      
      return this.http.get(`EntityAdminEntity/entity-groups/${entityGuid}`, { params })
      .subscribe((res: any) => {
        this.userGroupsStore.setUserGroups(res);
        this.userGroupsStore.setUserGroupsLoading(false);
      })
    }
}

