<div class="login">
  <!-- TODO: Verify appearance outlined is what we want -->
  <mat-card appearance="outlined" class="checkinbox-element">
    <mat-card-content>
      <div class="blank-page-min-height">
        <div class="sm:w-2/4 text-center mx-auto bg-white site-container-760 p-10">
          <h1 class="text-primary mb-10">{{ title }}</h1>
          <div class="text-primary" [innerHTML]="content"></div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>


