<ng-container *ngIf="!hasActiveSubscription; else subscribed">
  <app-boxy-message   
    [boxyMessageOptions]="boxyMessage"
    (buttonClicked)="onBoxyButtonClick()"
  ></app-boxy-message>
</ng-container>
<ng-template #subscribed>
  <div>
    <div class="content-container w-full h-full" *ngIf="upgradeRequired">
      <img src="assets/images/features-unavailable/learner-metrics.png" class="w-full h-full" />
    </div>
    <div class="content-container">
      <h1 class="learner mb-2">Import Learners</h1>
      <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
    </div>
  </div>
</ng-template>